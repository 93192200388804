import * as actionTypes from './ActionTypes';
import { Auth } from 'aws-amplify';
import Kritiq from '../../Api/Kritiq/Library';


export const setUserCognito = (user) => {
  return {
    type: actionTypes.SET_USER_COGNITO,
    user: user
  }
}

export const setKritiqUser = (user) => {
  return {
    type: actionTypes.SET_USER_KRITIQ,
    user: user
  }
}

export const updateProfilePic = (data) => {
  console.log('profilepic data', data);
  data.payload.profilePicture = true;
  return dispatch => {
    Kritiq.updateDoc('user', data)
    .then( results => {
      console.log('update user data', results);
      return getKritiqUser(dispatch, data.payload.username);
    })
    .catch(err=>{
      console.log('[update user Error]', err);
    })
  }
}

export const getKritiqUser = (dispatch, username) => {
  return Kritiq.viewDoc('user', 'username', username)
    .then(data=> {
      return dispatch(setKritiqUser(data.result[0]))
    })
    .catch(err=>console.log(err))
}

export const logUserIn = (credentails) => {
  return dispatch => {
    dispatch({type: actionTypes.LOGIN_START})
    Auth.signIn(credentails.username, credentails.password)
    .then(res=>{
      dispatch(setUserCognito(res));
      return res.username;
    })
    .then(username => {return getKritiqUser(dispatch, username)})
    .catch(err=>{
      console.log('[LOGIN ERR]', err);
      if (err.code === 'UserNotConfirmedException' ) {
        dispatch({type: actionTypes.SET_USERNAME, username: credentails.username, auth: credentails.password })
        dispatch({type:actionTypes.USER_NEEDS_CONFIRMATION})
      } else {
        dispatch({type: actionTypes.LOGIN_FAILED, msg: err.message})
      }
    })
  }
}

export const logUserOut = () => {
  return dispatch => {
    Auth.signOut()
      .then(results => console.log('[signing out]', results))
      .catch(err => console.log('[signing out error]', err));
    dispatch({type: actionTypes.LOGOUT_START})
  }
}

// checks if the user is logged
export const isUserLogged = () => {
  return dispatch => {
    Auth.currentAuthenticatedUser()
    .then(res=>{
      dispatch({type: actionTypes.LOGGED_STATUS, logged: true});
      dispatch(setUserCognito(res))
      return getKritiqUser(dispatch, res.username)
    })
    .catch(err=>{
      dispatch({type: actionTypes.LOGGED_STATUS, logged: false});
      console.log('[AUTH ISUSERLOGGED]', err)
    })
  }
}


export const ChangePassword = (oldPass, newPass) => {
	return dispatch => {
		Auth.currentAuthenticatedUser()
		.then(user => {
			return Auth.changePassword(user, oldPass, newPass);
		})
		.then(data => {
      console.log('[Password change success]', data);
      dispatch({type: actionTypes.PASSWORD_CHANGED});
    })
		.catch(err => console.log(err));

	}


}
