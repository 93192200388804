import React, { Component } from 'react'
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import * as watchlistActions from '../../store/Actions/Watchlist';

import { ReactComponent as PlusIcon } from '../../assets/Icons/plus-solid.svg';
import { ReactComponent as MinusIcon } from '../../assets/Icons/minus-solid.svg';

import './WatchlistButton.scss';

class WatchlistButton extends Component {
  state = {
    user: null,
    cognito: null,
    watchlist: false
  }

  componentDidMount () {
   
    if (this.props.user) {
      this.setState({
        user: this.props.user
      })
    }
    if (this.props.cognito) {
      this.setState({
        cognito: this.props.cognito
      })
    }
    if (this.props.watchlist) {
      this.checkforMovie();
    }
  }

  componentDidUpdate (prevProps, prevState) {
    
    if (this.props.user !== prevProps.user) {
      this.setState({
        user: this.props.user
      })
    }
    if (this.props.cognito !== prevProps.cognito) {
      this.setState({
        cognito: this.props.cognito
      })
    }
    if (this.props.watchlist !== prevProps.watchlist) {
      this.setState({
        watchlist: false
      })
      this.checkforMovie();
    }
  }

  checkforMovie = () => {
 
    if (this.props.watchlist) {
      this.props.watchlist.forEach(movie => {
        if (Number(movie.api_code) === Number(this.props.data.id)) {
          this.setState({
            watchlist: true
          })
        }
      })
    }
  }

  updateWatchlist = () => {
    
        let obj = {
            id: this.props.data.id,
            poster: this.props.data.poster_path,
            title: this.props.data.title,
            type: 'movie'
        }
        this.props.updateWatchlist(this.state.user._id, obj, this.state.cognito.signInUserSession.idToken.jwtToken)
  }

  render () {
    return (
      <div className='watchlist'>

          {/* too add to watchlist */}
        {
            this.state.user && this.state.cognito && !this.state.watchlist 
            ? 
            <div onClick={() => this.updateWatchlist()} className='watchlist__button add'>
                <PlusIcon />
                watchlist
            </div> 
            :
            null
        }
	
        
        {
            this.state.user && this.state.cognito && this.state.watchlist 
            ? 
            <div onClick={() => this.updateWatchlist()} className='watchlist__button remove'>
                <MinusIcon />
                watchlist
            </div> 
            : 
            null
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
    return {
      user: state.user.kritiqUser,
      cognito: state.user.cognitoUser,
      watchlist: state.profile.watchlist
    }
}
const mapDispatchToProps = dispatch => {
    return {
      updateWatchlist: (user, data, token) => dispatch(watchlistActions.updateWatchlist(user, data, token)),
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(WatchlistButton));
