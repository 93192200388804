import React from 'react'
import './RegistrationForm.scss';


const RegistrationForm = props => {


    return (
        <div className=' card-1 form-holder--registation'>
              <div className='form-1'>
                <h3 className="notification-holder">{props.header}</h3>
                <div className="bar gradient"></div>

                <div className="input-holder">
                        <input type='text'  name='username' value={props.values.username.value} onChange={(e) => props.inputChange(e, 'username')} />
                        <label htmlFor='username' className="floating-label">Username</label>
                        { props.errors && props.errors.username ?  <p className="err-msg">{props.errors.username[0]}</p> : null}
                </div>

                <div className="input-holder">
                        <input type='text'  name='email' value={props.values.email.value} onChange={(e) => props.inputChange(e, 'email')} />
                        <label htmlFor='email' className="floating-label">Email</label>
                        { props.errors && props.errors.email ?  <p className="err-msg">{props.errors.email[0]}</p> : null}
                </div>

                <div className="input-holder">
                    <input  type='text' name='password'  value={props.values.password.value} onChange={(e) => props.inputChange(e, 'password')}/>
                    <label htmlFor='password' className="floating-label">Password</label>
                    { props.errors && props.errors.password ?  <p className="err-msg">{props.errors.password[0]}</p> : null}
                </div>
                <div className="input-holder">
                    <input  type='text' name='confirm-password' value={props.values.confirmPassword.value} onChange={(e) => props.inputChange(e, 'confirmPassword')}/>
                    <label htmlFor='confirm-passwor' className="floating-label">Confirm Password</label>
                    { props.errors && props.errors.confirm ?  <p className="err-msg">{props.errors.confirm[0]}</p> : null}
                </div>

                <div className="submit-button gradient" onClick={props.register}>
                    Register
                </div>

            </div>
        </div>
    )
}


export default RegistrationForm
