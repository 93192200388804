import React from 'react'
import {withRouter} from 'react-router-dom';
import './GenreCard.scss';

const PlaylistCard = props => {

    const goTo = playlist => props.history.push(`/movies/${playlist.toLowerCase()}`)


    return (
        <div className='genre-card' onClick={ ()=> goTo(props.genre)}>
          <div className='image__holder'>
            <p className='data__holder__name'>{props.playlist}</p>
          </div>
        </div>
    )
}

export default withRouter(PlaylistCard);
