
import React from 'react'
import Poster from '../../Media/Poster/Poster';
import './WatchListListview.scss';

const WatchListView = props => {

    return (
        <div className='watchlist-item'>
            <div className='back-item'>
                <Poster path={props.movie.poster_path} size={'original'} />
            </div>
            <div className='watchlist-item__info-holder'>
                <div className='poster__holder'>
                    <Poster path={props.movie.poster_path} size={'original'} />
                </div>
                <div className='meta-data'>
                    <p className='meta-data__title'>{props.movie.movie_title}</p>
                    <div>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default WatchListView;