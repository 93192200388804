import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as movieActions from '../../../store/Actions/Movie';
import * as actionTypes from '../../../store/Actions/ActionTypes';
import MovieDetailsView from '../../../components/Media/PageViews/Movie/MovieDetailsView';
import MovieReviewCard from '../../../components/Review/ReviewCard/Movie/MovieReviewCard';
import Review from '../../Review/Review';
import CommentModal from '../../../components/Comments/CommentModal';

import './Movie.scss';
import ReviewButton from '../../Review/ReviewButton/ReviewButton';
import CreditList from '../../../components/Media/PageViews/Movie/CreditList/CreditList';
import MovieInfoList from '../../../components/Media/PageViews/Movie/InfoList/MovieInfoList';
import SideList from '../../../components/UI/List/SideList/SideList';



class Movie extends Component {

  state = {
    modal: false,
    singleReview: null
  }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.details ? this.outdatedInfoCheck(this.props.details.id, this.props.match.params.id) : this.getMovieInfo(this.props.match.params.id)
        // this.props.getDetails(this.props.match.params.id);


    }

    componentDidUpdate (prevProps, prevState) {
      if (this.props.details !== prevProps.details && this.props.details) {
        this.props.getReviews(this.props.details.id);
      }
      if (this.props.trailer !== prevProps.trailer && this.props.trailer) {
        window.open(`https://www.youtube.com/watch?v=${this.props.trailer.key}`)
      }
    }

    // checks to make sure old info in not Shown
    outdatedInfoCheck = (old, updated) => {
        if (old !== updated ) {
            this.props.reset();
            this.getMovieInfo(updated)
        }
    }


    getMovieInfo = movieId => {
        this.props.getDetails(movieId)
        this.props.getCredits(movieId)
    }

    toggleModal = (event, review) => {
      event.stopPropagation();
      this.setState({
        modal: !this.state.modal,
        singleReview: review
      })
    }

    getTrailer = () => {
      console.log('this.props.details', this.props.details);
      this.props.getTrailer(this.props.details.id);
    }

    render() {

        return (
            <div className='movie-view-page'>
             { this.state.modal ? <CommentModal review={this.state.singleReview} toggleModal={this.toggleModal} /> : null }

                <div className='view-page'>

                    <MovieDetailsView getTrailer={this.getTrailer} details={this.props.details}/>
                    <MovieInfoList details={this.props.details}/>
                    <CreditList credits={this.props.credits} />



                    <div className='friend-reviews'>
                        <p>What my Friends Said</p>

                        {
                                this.props.movieReviews && this.props.following ? this.props.movieReviews.map( (review, index) => {
                            let temp;
                            this.props.following.following.forEach( follow => {
                                if (follow.data.following === review.data.user_id) {
                                temp = <MovieReviewCard key={index} review={review} toggleModal={this.toggleModal} hidePoster={true}/>
                                }
                            })
                            return temp;

                            }) : null
                        }

                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        credits: state.movie.credits,
        creditsLoading: state.movie.creditsLoading,
        details: state.movie.details,
        loading: state.movie.loading,
        following: state.profile.followData,
        movieReviews: state.movie.movieReviews,
        trailer: state.movie.trailer
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getTrailer : (id) => dispatch(movieActions.getMovieTrailer(id)),
        getReviews : (id) => dispatch(movieActions.getMovieReviews(id)),
        getDetails : (id) => dispatch(movieActions.getMovieDetails(id)),
        getCredits: (id) => dispatch(movieActions.getCreditDetails(id)),
        reset: () => dispatch({type: actionTypes.RESET_MOVIE_DETAILS})
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(Movie);
