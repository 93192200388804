import React, { Component } from 'react'
import {connect} from 'react-redux';

import RegistrationForm from '../../components/Registration/Form/RegistrationForm';
import { withRouter } from 'react-router-dom';

import { checkRegistrationForError } from './ValidationTypes';
import * as registerActions from '../../store/Actions/Registration';
import './Register.scss'


class Register extends Component {

    state = {
      errors: null,
      registrationInfo : {
        username: {value: '', inputError: null},
        email: {value: '', inputError: null},
        password: {value: '', inputError: null},
        confirmPassword: {value: '', inputError: null},
      }
    }

    componentDidUpdate() {
        if (this.props.needsVerification) {
            this.props.history.push('/verify-account')
        }
        if (this.props.logged) {
          this.props.history.push('/');
        }
    }

    registrationValidation = data => {
        const status = checkRegistrationForError(data);
        if (!status.validCredentials) {
          this.setState({
            errors: status.errors
          })
          return false;
        } else {
          return true;
        }
    }

    inputChangeHandler = (e, identifier) => {
        let updatedRegistrationInfo = { ...this.state.registrationInfo }
        let updatedValue = updatedRegistrationInfo[identifier];
        updatedValue.value = e.target.value;
        this.setState({registrationInfo:updatedRegistrationInfo})
    }

    submitRegistration = () => {
        if (this.registrationValidation(this.state.registrationInfo)) {
          this.props.register(this.state.registrationInfo);
        }
    }

    render( ){
      return (

        <div className='registration__holder'>

          <RegistrationForm
            header='Create an Account'
            inputChange={this.inputChangeHandler}
            errors={this.state.errors}
            values={this.state.registrationInfo}
            register={this.submitRegistration}/>

        </div>
      )
    }
}


const mapStateToProps = state => {
    return {
        error: state.register.err,
        loading: state.register.loading,
        needsVerification: state.register.emailVerification,
        logged: state.auth.logged
    }
}
const mapDispatchToProps = dispatch => {
    return {
        register: (data) => dispatch(registerActions.register(data))
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Register));
