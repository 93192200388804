import React from 'react'

const ActorTopInfoLoader = (props) => {


    return (
        <div className='top-hold'>
        <div className='img-holder filler'>
           
        </div>
        <div className='data-holder'>
            <div className='actor--name filler med text' ></div>
        </div>


    </div>
    )
}

export default ActorTopInfoLoader;