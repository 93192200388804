
import * as actionTypes from './ActionTypes';
import Kritiq from '../../Api/Kritiq/Library';


export const checkForReview = data => {
    return dispatch => {
        dispatch({type: actionTypes.GET_MOVIE_REVIEW_START})
        Kritiq.checkForReview(data)
        .then(res=>{ dispatch({ type: actionTypes.GET_MOVIE_REVIEW_SUCCESS, review: res.result })})
        .catch(err=>console.log('[ERR CHECKING REVIEW]', err))
    }
}


export const saveReview = data => {
    const modelType ='movie_review'
    return dispatch => {
        dispatch({type: actionTypes.SAVE_REVIEW_START})
        Kritiq.updateDoc(modelType, data)
        .then(res=> dispatch({type: actionTypes.SAVE_REVIEW_SUCCESS, review: res.result}))
        .catch(err=>console.log(err))
    }
}


export const createMovieReview = data => {
    return dispatch => {
        dispatch({type: actionTypes.CREATE_MOVIE_REVIEW_START})
        Kritiq.createDoc(data.model, data.auth, data.payload)
        .then(res=>{ dispatch({type: actionTypes.CREATE_MOVIE_REVIEW_SUCCESS , review: res.result}) })
        .catch(err=>console.log('[ERR CREATING REVIEW]', err))
    }
}

export const getReviewById = id => {
    return dispatch => {
        Kritiq.getById('movie_review', id)
        .then(res=>{
          dispatch({type: actionTypes.SINGLE_REVIEW, review: res.result})
        })
        .catch(err=>console.log(err))
    }
}

export const getComments = id => {
    return dispatch => {
      let query = { 'parent_id' : id };
      console.log('[query]', query);
      return Kritiq.search('comment', query)
        .then(res=>{
          console.log('got comments', res);
          dispatch({type: actionTypes.GOT_COMMENTS, comments: res.result})
        })
        .catch(err=>console.log(err))
    }
}
