import React, {Component} from 'react'
import { connect } from 'react-redux';
import './MoviesLanding.scss';

import * as moviesActions from '../../store/Actions/Movies';

import MoviesBanner from '../../components/UI/Banner/Movies/MoviesBanner';
import HorizontalList from '../../components/UI/List/HorizontalList/HorizontalList';
import SideList from '../../components/UI/List/SideList/SideList';


class Dashboard extends Component {

    state = {
      playlists: ['Star Wars', 'Alfred Hitchcock', 'John Hughes', 'Based on Books', 'Kids & Family']
    }

    componentDidMount () {
        this.refeshContentCheck()
    }

    componentDidUpdate (prevProps, prevState) {
        if (this.props.featured !== prevProps.featured) {
        }
    }

    refeshContentCheck = () => {
      this.props.getMovies();
      this.props.getGenres()

    }

    updateFilter = (event) => {
      let val = event.target.value;
      this.props.getMovies(val);
    }



    render () {
        return (
            <div className='movies-landing'>
              <div className='view-page'>
                {/* <MovieReviewCard movie={'data'} /> */}

                <MoviesBanner data={this.props.featured}/>


        


                <HorizontalList title='Featured' data={this.props.featured}/>
                {/* <HorizontalList type='genre' title='Genres' data={this.props.genres}/> */}
                <HorizontalList title='Now Playing' data={this.props.nowPlaying}/>
                <HorizontalList title='Upcoming' data={this.props.upcoming}/>

                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        user: state.user.cognitoUser,
        // userId: state.user.userId
        featured: state.movies.featured,
        genres: state.movies.genres,
        nowPlaying: state.movies.nowPlaying,
        upcoming: state.movies.upcoming
    }
}

const mapDispatchToProps = dispatch =>{
    return {
        getMovies: (type) => dispatch(moviesActions.getMovies(type)),
        getGenres: () => dispatch(moviesActions.getGenres())
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
