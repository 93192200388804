import React from 'react'
import './MediaTile.scss';


const MovieTileLoader = props => {

    return (
        <div className='media-tile--loader'>
            
        </div>
    )
}

export default MovieTileLoader;