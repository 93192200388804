import React from 'react'
import './MovieDetailsView.scss';
import Poster from '../../Poster/Poster';
import WatchlistButton from '../../../Watchlist/WatchlistButton';
// import { timeFilter } from '../../../../Utility/Utility';
// import HorizontalList from '../../../UI/List/HorizontalList/HorizontalList';
import MovieDetailsLoader from './Loader';
// import Palette from 'react-palette';
import {ReactComponent as PlusIcon} from '../../../../assets/Icons/plus-solid.svg';
import {ReactComponent as PlayIcon} from '../../../../assets/Icons/play-circle-solid.svg';
import ReviewButton from '../../../../containers/Review/ReviewButton/ReviewButton';

const MovieDetailsView = props => {




    const imageURL = 'https://image.tmdb.org/t/p/original/rjbNpRMoVvqHmhmksbokcyCr7wn.jpg'

    return (

        <div className='movie-top'>


            <div className='movie-top--graphic'>

                <div className='movie-graphic'>
                    {
                        props.details
                        ?

                                props.details.backdrop_path
                                ?
                                <Poster path={props.details.backdrop_path} size={'original'}/>
                                :
                                <div className='no-image'></div>

                        :
                        null
                    }

                    {
                        props.details
                        ?
                        <div className='movie-graphic__title'>
                            <p>{props.details.title}</p>
                            <Poster path={props.details.backdrop_path} size={'original'}/>
                        </div>
                        :
                        null
                    }


                </div>







                <div className='movie__buttons'>


                    <div className='row--top'>

                        <div className='button trailer' onClick={props.getTrailer}>
                            <PlayIcon/>
                            <p>Trailer</p>
                        </div>

                        {
                            props.details ?
                            <WatchlistButton data={props.details}/>
                            :
                            null
                        }
                    </div>
                    <div className='row--bottom'>

                        {
                            props.details ?
                            <ReviewButton movie={props.details}/>
                            : null
                        }

                    </div>




                </div>


            </div>







        </div>
    )
}

export default MovieDetailsView;



// {/* <p className='movie-details__title'>{props.details.title}</p>
// <p className='movie-details__type'>PG <span> |</span> {timeFilter(props.details.runtime)}</p>
// <div className='movie-details__genre'>
//     {props.details.genres.map(genre=> <div key={genre.name} className='genre-pill'>{genre.name}</div> )}
// </div> */}
