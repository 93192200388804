import * as actionTypes from '../Actions/ActionTypes';

const initialState = {
	loading: false,
	details : null,
	error: false,
	credits: [],
	creditsLoading: false,
	reviews: [],
	movieReviews: [],
	trailer: null
}

const loading = state => {
	return {
		...state,
		loading: true,
		details: null,
	}
}

const setTrailer = (state, action) => {
	return {
		...state,
		trailer: action.trailer
	}
}

const setMovie = (state, action) => {
	return {
		...state,
		loading: false,
		details: action.movie
	}
}

// const setCast = (state, action) => {
//   return {
//     ...state,
//     cast: action.cast
//   }
// }

const loadingCredits = state => {
	return {
		...state,
		credits: [],
		creditsLoading: true,

	}
}

const setMovieReviews = (state, action) => {
	return {
		...state,
		movieReviews: action.movieReviews
	}
}


const setCredits = (state, action) => {
	return {
		...state,
		credits: action.credits,
		creditsLoading: false,
	}
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case (actionTypes.GOT_MOVIE_REVIEWS) : return setMovieReviews(state, action);
		case (actionTypes.GET_MOVIE_START) : return loading(state);
		case (actionTypes.GET_MOVIE_TRAILER) : return setTrailer(state, action);
		case (actionTypes.GET_MOVIE_SUCCESS) : return setMovie(state, action);
		case (actionTypes.RESET_MOVIE_DETAILS): return initialState;
		case (actionTypes.GET_MOVIE_CREDITS_START) : return loadingCredits(state);
		case (actionTypes.GET_MOVIE_CREDITS_SUCCESS) : return setCredits(state, action)
		default: return state;
	}
}


export default reducer;
