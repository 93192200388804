import * as actionTypes from '../Actions/ActionTypes';

const initalState = {
  emailVerification: false,
  loading: false,
  error: {
    status: false,
    msg: null
  }
}

const loading = (state) => {
  return {
    ...state,
    loading: true,
    error: {
      status: false,
      msg: null
    }
  }
}

const finishedLoading = (state) => initalState;

const reducer = (state = initalState, action) =>{

  switch (action.type) {
    case (actionTypes.USER_NEEDS_CONFIRMATION): return {...state, emailVerification: true}
    case (actionTypes.REGISTER_START) : return loading(state);
    case (actionTypes.REGISTER_SUCCESS) : return finishedLoading(state)
    default: return state
  }
}


export default reducer;
