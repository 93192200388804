import React, { Component } from 'react'
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import * as navBarActions from '../../store/Actions/Navbar';
import Topbar from '../../components/Navigation/Topbar/Topbar';
// import Sidebar from '../../components/Navigation/Sidebar/Sidebar'

class Navigation extends Component {

  state = {

  }

  componentDidMount () {

  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.dashContent === null && this.props.user) {
      let place = this.props.location.pathname === '/' ? true : false;
      this.props.fetchUserInfo(this.props.user, place);
    }
    if (prevProps.user !== this.props.user && !prevProps.user) {
      let place = this.props.location.pathname === '/' ? true : false;
      this.props.fetchUserInfo(this.props.user, place);
    }
  }

  submitSearch = (word) => {
    this.props.history.push('/search?query=' + word)
  }

  render () {
      return (
        <React.Fragment >
          <Topbar className={this.props.topClass} user={this.props.user} search={(word) => this.submitSearch(word)}/>
          {/* <Sidebar className={this.props.sideClass}/> */}
        </React.Fragment>
      )
  }
}

const mapStateToProps = state => {
    return {
      user: state.user.kritiqUser,
      dashContent: state.profile.dashContent
    }
}
const mapDispatchToProps = dispatch => {
    return {
      fetchUserInfo: (user, place) => dispatch(navBarActions.getUserInfo(user, place))
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Navigation));
