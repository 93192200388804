import React, {Component} from 'react'
import {connect} from 'react-redux';

import {verifyUser} from '../../../store/Actions/Registration';

class VerifyAccount extends Component  {


    state = {
        code: ''
    }

    componentDidMount() {
        if (!this.props.username) {

        }
    }
    inputHandler = (event) => {
        this.setState({ code: event.target.value })
    }


    onSubmitHandler = () => {
        if (this.props.username) {
            let data = {
                username: this.props.username,
                auth: this.props.auth,
                code: this.state.code
            }
            this.props.verify(data)
        }
    }


    render() {
        return (
            <div className=' card-1 form-holder--registation'>
                    <div className='form-1'>
                    <h3 className="notification-holder">Please Check Your Email To Verify Account</h3>
                    <div className="bar gradient"></div>

                    <div className="input-holder">
                            <input type='text'  name='verifyCode'  value={this.state.code} onChange={(event) => this.inputHandler(event)}/>
                            <label htmlFor='verifyCode' className="floating-label">Verification Code</label>
                    </div>

                    <div className="submit-button gradient" onClick={this.onSubmitHandler} >
                        Verify
                    </div>

                </div>
            </div>
        )
    }
}


const mapStateToProps = state=> {
    return {
        username: state.user.username,
        auth: state.user.auth

    }
}

const mapDispatchToProps = dispatch => {
    return {
        verify: (info) => dispatch(verifyUser(info))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccount);
