import  React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import './UserUtils.scss';

class UserUtils extends Component {

  state = {
    dropdown: false
  }

  dropdownHandler = (e) => {
    this.setState({dropdown: true})
    document.addEventListener('click', this.closeDropdownsHandler)
  }


  closeDropdownsHandler = () =>{
    this.setState({dropdown: false}, () => {
      document.removeEventListener('click', this.closeDropdownsHandler);
    });
  }

  goTo = path => this.props.history.push(path)

  render () {
    return (
      <div className='user-utils'>
        <div className='profile-icon' onClick={(e) => this.dropdownHandler(e)} >
          { this.props.user && this.props.user.profilePicture ? <img src={`https://s3.us-west-1.amazonaws.com/kritiq/public/profilePics/${this.props.user.username}.jpg`} alt='profileImg' /> : <p>{this.props.user.username[0].toUpperCase()}</p> }
          { /* this.props.user ? <p>{this.props.user.username[0].toUpperCase()}</p> : null */}
        </div>
        <div className={`utils--dropdown ${this.state.dropdown ?  'show': ''}`}>
          <div className='item' onClick={() => this.goTo('/profile')}>
            Profile
          </div>
          <div className='item' onClick={() => this.goTo('/account-settings')}>
            Settings
          </div>
          <div className='item' onClick={() => this.goTo('/logout')}>
            Logout
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.kritiqUser
  }
}
const mapDispatchToProps = dispatch => {
  return {
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(UserUtils));
