import * as actionTypes from './ActionTypes';
import { Auth } from 'aws-amplify'
import kritiq from '../../Api/Kritiq/Library';
import * as authTypes from './Auth';


export const register = (userInfo) => {
  const user = {
    username: userInfo.username.value,
    password: userInfo.password.value,
    attributes: {email: userInfo.email.value}
  }
  return dispatch => {
    Auth.signUp(user)
    .then(res=> {
      dispatch({type: actionTypes.SET_USERNAME, username: user.username, auth: user.password })
      dispatch(dispatch({type:actionTypes.USER_NEEDS_CONFIRMATION}))
    })
    .catch(err=>console.log('[Registration ERR]', err))
  }
}

export const verifyUser = data => {
  return dispatch => {
    Auth.confirmSignUp(data.username, data.code, {forceAliasCreation: true })
    .then(res=>{
      return signInAndCreateUser(data)
    })
    .then(data=>{
      dispatch(authTypes.setUserCognito(data))
      return createKritiqUser({username: data.username, tkn: data.signInUserSession.idToken.jwtToken})
    })
    .then(data=>{
      dispatch(authTypes.setKritiqUser(data.result.newUser))
    })
    .catch(err=>console.log('[Verification Confirmation ERROR]', err))
  }
}


const signInAndCreateUser = (data) => {
  return Auth.signIn(data.username, data.auth)
  .then(res=>{return res})
  .catch(err=>console.log('[signInAndCreateUser ERROR]', err))
}


export const createKritiqUser = data => {
  const newUser = {username: data.username}
  return kritiq.createDoc("user", data.tkn , newUser)
  .then(res=>{return res})
  .catch(err=>{ console.log(err)})
}
