import React, {Component} from 'react'
import { connect } from 'react-redux';
import './DiscoverLanding.scss';
import * as moviesActions from '../../../store/Actions/Movies';
import DiscoverFilter from '../../../components/UI/Filter/DiscoverFilter';
import MediaTile from '../../../components/Media/MediaTile/MediaTile';


class Discover extends Component {

    state = {
      playlists: ['Star Wars', 'Alfred Hitchcock', 'John Hughes', 'Based on Books', 'Kids & Family'],
      showFilterDiv: false,
      movies: null
    }

    componentDidMount () {
      this.props.getGenres()
      if (this.props.match.params.filter) {
        if (this.props.match.params.filter === 'now_playing') {
          this.props.getMovies('now_playing');
        }
        else if (this.props.match.params.filter === 'popular') {
          this.props.getMovies('popular');
        }
        else if (this.props.match.params.filter === 'top_rated') {
          this.props.getMovies('top_rated');
        }
        else if (this.props.match.params.filter === 'upcoming') {
          this.props.getMovies('upcoming');
        } else {
          if (this.props.genres.length > 0) {

            this.props.genres.forEach(genre => {
              if (this.props.match.params.filter === genre.name.toLowerCase()) {

                this.props.getGenreMovies(genre.id);
              }
            })
          } else {

            this.props.getGenres()
          }
        }
      } else {
        this.props.getMovies();
      }
    }

    componentDidUpdate (prevProps, prevState) {
      if (this.props.match.params.filter !== prevProps.match.params.filter) {
        if (this.props.match.params.filter === 'now_playing') {
          this.props.getMovies('now_playing');
        }
        else if (this.props.match.params.filter === 'popular') {
          this.props.getMovies('popular');
        }
        else if (this.props.match.params.filter === 'top_rated') {
          this.props.getMovies('top_rated');
        }
        else if (this.props.match.params.filter === 'upcoming') {
          this.props.getMovies('upcoming');
        } else {
          if (this.props.genres.length > 0) {

            this.props.genres.forEach(genre => {
              if (this.props.match.params.filter === genre.name.toLowerCase()) {

                this.props.getGenreMovies(genre.id);
              }
            })
          } else {

            this.props.getGenres()
          }
        }
      }
      if (this.props.genres !== prevProps.genres) {

        this.props.genres.forEach(genre => {
          if (this.props.match.params.filter === genre.name.toLowerCase()) {
            this.props.getGenreMovies(genre.id);

          }
        })
      }
        if (this.props.featured !== prevProps.featured) {

          this.setState({
            movies: this.props.featured
          })
        }
    }

    refeshContentCheck = () => {
        if (this.props.featured.length < 1 ) {
            this.props.getMovies();
            this.props.getGenres()
        }
    }

    updateFilter = (name) => {
      this.props.history.push('/movies/discover/' + name.toLowerCase())
      this.showFilter()
    }

    showFilter = () => {
      this.setState({
        showFilterDiv: !this.state.showFilterDiv
      })
    }


    render () {

        return (
            <div className='view-page discover'>
              <div className='discover__title'>
                <p>Discover</p>
                <div className='grad-bar gradient'></div>
              </div>
              <DiscoverFilter update={this.updateFilter} genres={this.props.genres} />
              <div className='movie-list__holder'>
                { this.state.movies ? this.state.movies.map(movie=>(
                  <div className='movie-tile__holder'>
                    <MediaTile media={movie} type={'movie'} key={movie.id}/>
                  </div>
                )) : null}
              </div>


            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        user: state.user.cognitoUser,
        featured: state.movies.featured,
        genres: state.movies.genres,
        nowPlaying: state.movies.nowPlaying,
        upcoming: state.movies.upcoming
    }
}

const mapDispatchToProps = dispatch =>{
    return {
        getMovies: (type) => dispatch(moviesActions.getMovies(type)),
        getGenreMovies: (genreId) => dispatch(moviesActions.getGenreMovies(genreId)),
        getGenres: () => dispatch(moviesActions.getGenres())
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(Discover)
