import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';

import './Topbar.scss';
import UserUtils from '../../../containers/Navigation/UserUtils/UserUtils';
import Button from '@material-ui/core/Button';

const topbar = (props) =>{

    const goTo = path => props.history.push(path)

    const userUtils = props.user ?
        <UserUtils />
        : <Button onClick={()=> goTo('/login')} className='topbar__login-btn'>
                <p>Login</p>
          </Button>


    // const submit = e => {
    //     var code = (e.keyCode ? e.keyCode : e.which);
    //     var value = e.target.value;
    //     if (code === 13) {
    //         props.search(value);
    //     }
    // }


    return (
        <div className={`${props.className} topbar space-black`}>
            <div className='topbar__left-side'>
                <NavLink  exact className=' topbar__logo btn underline--gradient' to='/'>Kritiq<span>(beta)</span></NavLink>
                <div className='topbar__navLinks'>
                    <NavLink className='topbar__navLinks__link btn underline-grad' to='/movies'>Movies</NavLink>
                    {/* <NavLink className='topbar__navLinks__link btn underline-grad' to='/discover'>Discover</NavLink> */}
                    {/* <NavLink className='topbar__navLinks__link btn underline-grad' to='/watchlist'>Watchlist</NavLink> */}
                    <NavLink className='topbar__navLinks__link btn underline-grad' to='/search'>Search</NavLink>
                </div>
            </div>
            <div className='topbar__right-side'>
                <div className='topbar__user-info'>
                    {userUtils}
                </div>
            </div>
        </div>
    );
}

export default withRouter(topbar);


// <div className='topbar__search'>
// <input type='text' placeholder='Search Kritiq...' onKeyPress={(event) => submit(event)}/>
// </div>
