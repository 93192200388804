import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as navBarActions from '../../store/Actions/Navbar';
import * as profileActions from '../../store/Actions/Profile';
import Following from '../../components/Following/Following';
import { dateFilter } from '../../Utility/Utility';
import './Profile.scss';
import MovieReviewCard from '../../components/Review/ReviewCard/Movie/MovieReviewCard';
import Watchlist from '../Watchlist/Watchlist';
import CommentModal from '../../components/Comments/CommentModal';

class Profile extends Component {
  state = {
    profile: null,
    alt: false,
    followData: null,
    watchlist: null,
    reviews: null,
    value: null,
    view: 'reviews',
    average: 0,
    modal: false,
    singleReview: null
  }

  componentDidMount () {
    if (this.props.match.params.id) {
      this.props.fetchProfile(this.props.match.params.id, false);
      this.props.getMyReviews(this.props.match.params.id, false);
      this.props.getFollows(this.props.match.params.id, false);
      this.props.getWatchlist(this.props.match.params.id, false);
      this.props.getUser(this.props.match.params.id, false);
      this.setState({
        alt: true,
        value: false
      })
    } else {
      this.setState({
        value: true
      })
      if (this.props.followData) {
        this.setState({
          followData: this.props.followData
        })
      }
      if (this.props.myReviews) {
        this.setState({
          reviews: this.props.myReviews
        })
        this.getAverageScore(this.props.myReviews);
      } else {
        if (this.props.user) {
          this.props.getMyReviews(this.props.user._id, true);
          this.props.getFollows(this.props.user._id, true);
          this.props.getWatchlist(this.props.user._id, true);

        }
      }
      if (this.props.profile) {
        this.setState({
          profile: this.props.profile
        })
      } else {
        if (this.props.user) {
          this.props.fetchProfile(this.props.user._id, true);
        }
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.user !== this.props.user && !this.props.match.params.id) {
      this.props.fetchProfile(this.props.user._id, true);
      this.props.getMyReviews(this.props.user._id, true);
      this.props.getFollows(this.props.user._id, true);
      this.props.getWatchlist(this.props.user._id, true);

      this.setState({
        alt: false
      })
    }

    if (this.props.match.params.id && prevProps.user !== this.props.user && prevProps.match.params.id !==  !this.props.match.params.id) {
      this.props.fetchProfile(this.props.match.params.id, false);
      this.props.getMyReviews(this.props.match.params.id, false);
      this.props.getFollows(this.props.match.params.id, false);
      this.props.getWatchlist(this.props.match.params.id, false);
      this.props.getUser(this.props.match.params.id, false);
      if (!this.state.alt) {
        this.setState({
          alt: true
        })

      }
    }
    if (prevProps.followData !== this.props.followData && !this.props.match.params.id) {
      this.setState({
        followData: this.props.followData
      })
    }
    if (prevProps.otherFollowData !== this.props.otherFollowData && this.props.match.params.id) {
      this.setState({
        followData: this.props.otherFollowData
      })
    }
    if (prevProps.watchlist !== this.props.watchlist) {
      console.log('watchlist changed', this.props.watchlist);
      this.setState({
        watchlist: this.props.watchlist
      })
    }
    if (prevProps.reviews !== this.props.reviews && this.props.match.params.id) {
      let updated = this.props.reviews.sort(function(a, b) {
        a = new Date(a.data.updated_at);
        b = new Date(b.data.updated_at);
        return a>b ? -1 : a<b ? 1 : 0;
      });
      this.getAverageScore(updated);
      this.setState({
        reviews: updated
      })
    }
    if (this.props.myReviews && prevProps.myReviews !== this.props.myReviews && !this.props.match.params.id) {
      let updated = this.props.myReviews.sort(function(a, b) {
        a = new Date(a.data.updated_at);
        b = new Date(b.data.updated_at);
        return a>b ? -1 : a<b ? 1 : 0;
      });
      this.getAverageScore(updated);
      this.setState({
        reviews: this.props.myReviews ? updated : this.state.reviews
      })
    }
    if (this.state.reviews !== prevState.reviews) {
    }
    if (prevProps.otherProfile !== this.props.otherProfile && this.props.match.params.id) {
      this.setState({
        profile: this.props.otherProfile
      })
    }
    if (prevProps.myProfile !== this.props.myProfile && !this.props.match.params.id) {
      this.setState({
        profile: this.props.myProfile
      })
    }
  }

  toggleModal = (event, review) => {
    event.stopPropagation();
    this.setState({
      modal: !this.state.modal,
      singleReview: review
    })
  }

  getAverage = array => {
    let total = 0;
    for (var i = 0; i < array.length; i++) {
      total += array[i];
    }
    return Math.floor(total/array.length);
  }

  getAverageScore = (array) => {
    let scoreArray = [];
    array.forEach( review => {
      scoreArray.push(Number(review.data.score));
    })
    console.log('scoreArray', scoreArray);
    let average = this.getAverage(scoreArray);
    this.setState({
      average: average
    })
  }

  changeView = view => {
    this.setState({
      view: view
    })
  }

  goTo = (id) => {
     if (id) {
       this.props.history.push('/profile/' + id)
     } else {
       this.props.history.push('/profile')
     }
  }

  render () {
    return (
      <div className='view-page'>
        { this.state.modal ? <CommentModal review={this.state.singleReview} toggleModal={this.toggleModal} /> : null }

        {this.state.profile ?

          <div className='wrapper'>
            <div className='profile-header'>

              <div className='profile-pic-holder'>

                { this.props.match.params.id ?
                  <div className='profile-pic'>
                    { this.props.otherUser && this.props.otherUser.profilePicture ? <img src={`https://s3.us-west-1.amazonaws.com/kritiq/public/profilePics/${this.props.otherUser.username}.jpg`} alt='profileImg' /> : <span>{ this.state.profile ? this.state.profile.first_name[0] : null }</span> }
                  </div>
                : <div className='profile-pic'>
                  { this.props.user && this.props.user.profilePicture ? <img src={`https://s3.us-west-1.amazonaws.com/kritiq/public/profilePics/${this.props.user.username}.jpg`} alt='profileImg' /> : <span>{ this.state.profile ? this.state.profile.first_name[0] : null }</span>}
                </div>
                }

                <div className='profile-name'>
                  <h3>{ this.state.profile.first_name } { this.state.profile.last_name }</h3>
                  <p>{ this.state.alt ? null : '@' + this.props.user.username }</p>
                  { this.state.alt && this.props.user ? <div><Following current={this.props.user._id} other={this.props.match.params.id} value={this.state.value}></Following></div> : null }
                </div>
              </div>

              <div className='profile-info'>
                <div className='profile-bio'>
                  <p className='bold'>{this.state.profile.bio}</p>
                </div>
                <div className='profile-stats'>
                  <p>Average Rating is { this.state.average }%</p>
                  <p>Joined {dateFilter(this.state.profile.created_at, 'short')}</p>
                </div>
                <div className='profile-follow'>
                  { this.state.followData && this.state.followData.following ? <p className={`${ this.state.view === 'following' ? 'selected' : null } follow-btn`} onClick={() => this.changeView('following')}><span className='bold'>{this.state.followData.following.length}</span> Following</p>: null }
                  { this.state.followData ? <p className={`${ this.state.view === 'followers' ? 'selected' : null } follow-btn`} onClick={() => this.changeView('followers')}><span className='bold'>{this.state.followData.followers.length}</span> Followers</p>: null }
                </div>
              </div>
            </div>

            <div className='tab-holder'>
              <p className={ this.state.view === 'reviews' ? 'active' : ''} onClick={() => this.changeView('reviews')}>Reviews</p>
              <p className={ this.state.view === 'watchlist' ? 'active' : ''} onClick={() => this.changeView('watchlist')}>Watchlist</p>
              { /* <p className={ this.state.view === 'followers' ? 'active' : ''} onClick={() => this.changeView('followers')}>Followers</p>
              <p className={ this.state.view === 'following' ? 'active' : ''} onClick={() => this.changeView('following')}>Following</p> */}
            </div>

            <div className='profile-main-holder'>

              { this.state.view === 'reviews' ? <div className='reviewDiv'>
                {this.state.reviews ?
                  this.state.reviews.map( (review, index) => (
                    <MovieReviewCard key={index} review={review} toggleModal={this.toggleModal} />
                  ))
                : null}
              </div> : null }

              { this.state.view === 'watchlist' ? <div className='watchlist-holder'>
                { this.state.watchlist ? <Watchlist watchlist={this.state.watchlist} /> : null }
              </div> : null }

              { this.state.view === 'followers' ? <div className='followHolder'>
                { this.props.user && this.state.followData && this.state.followData.followers.length > 0 ? this.state.followData.followers.map( (follow, index) => {
                  return <div className='followingDiv' onClick={() => this.goTo(this.props.user._id === follow.data.follower ? null : follow.data.follower)} key={index}>
                    { follow.follower.profilePicture ? <div className='follow-pic'><img src={`https://s3.us-west-1.amazonaws.com/kritiq/public/profilePics/${this.props.user._id === follow.data.follower ? this.props.user.username : follow.follower.username}.jpg`} alt='profileImg' /></div> : <span className='follow-pic'>{this.props.user._id === follow.data.follower ? this.props.user.username[0] : follow.follower.username[0]}</span>}
                    <p>{this.props.user._id === follow.data.follower ? this.props.user.username : follow.follower.username}</p>
                  </div>
                }) : null }
              </div> : null }

              { this.state.view === 'following' ? <div className='followHolder'>
                { this.state.followData && this.state.followData.following.length > 0 ? this.state.followData.following.map( (follow, index) => {
                  return <div className='followingDiv' onClick={() => this.goTo(follow.following._id)} key={index}>
                  { follow.following.profilePicture ? <div className='follow-pic'><img src={`https://s3.us-west-1.amazonaws.com/kritiq/public/profilePics/${this.props.user._id === follow.data.following ? this.props.user.username : follow.following.username}.jpg`} alt='profileImg' /></div> : <span className='follow-pic'>{this.props.user._id === follow.data.following ? this.props.user.username[0] : follow.following.username[0]}</span>}
                    <p>{follow.following.username}</p>
                  </div>
                }) : null }
              </div> : null }
            </div>

          </div>

        : null }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    myProfile: state.profile.userProfile,
    otherProfile: state.profile.otherProfile,
    user: state.user.kritiqUser,
    otherUser: state.profile.otherUser,
    followData: state.profile.followData,
    otherFollowData: state.profile.otherFollowData,
    reviews: state.profile.reviews,
    myReviews: state.profile.myReviews,
    watchlist: state.profile.watchlist,
    otherWatchlist: state.profile.otherWatchlist
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getUser: (id, value) => dispatch(profileActions.getUser(id, value)),
    getMyReviews: (user, value) => dispatch(profileActions.getMyReviews(user, value)),
    fetchProfile: (user, value) => dispatch(profileActions.getProfile(user, value)),
    getFollows: (user, value) => dispatch(navBarActions.getFollows(user, value)),
    getWatchlist: (user, value) => dispatch(navBarActions.getWatchlist(user, value)),

  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Profile));
