import * as actionTypes from '../Actions/ActionTypes';

const initialState = {
	query: '',
	results: {
		movies: [],
		profiles: [],
		users: [],
		actors: []
	}
}

const clearSearch = (state, action) => {
  return {
    ...state,
    results: {
      movies: [],
      profiles: [],
      users: [],
			actors: []
    }
  }
};

const setSearchResults = (state, action) => {
  let searchData = {...state.results}
	if (action.actors) {
    if (searchData.actors.length > 0) {
      searchData.actors.concat(action.actors)
    } else {
      searchData.actors = action.actors
    }
  }
  if (action.movies) {
    if (searchData.movies.length > 0) {
      searchData.movies.concat(action.movies)
    } else {
      searchData.movies = action.movies
    }
  }
  if (action.profiles) {
    if (searchData.profiles.length > 0) {
      searchData.profiles.concat(action.profiles)
    } else {
      searchData.profiles = action.profiles
    }
  }
  if (action.users) {
    if (searchData.users.length > 0) {
      searchData.users.concat(action.users)
    } else {
      searchData.users = action.users
    }
  }
  return {
    ...state,
    results: searchData
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case(actionTypes.CLEAR_SEARCH) : return clearSearch(state, action);
    case(actionTypes.SEARCH_RESULTS) : return setSearchResults(state, action);
	case(actionTypes.SET_SEARCH_QUERY): return {...state, query: action.query}
    default: return state;
  }
}

export default reducer;
