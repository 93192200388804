import React, { Component } from 'react'
import {connect} from 'react-redux';
import { Route } from 'react-router-dom';

import BackButton from '../../components/UI/Button/BackButton';
import ReviewForm from './ReviewForm/ReviewForm';
import ReviewView from './ReviewView/ReviewView';
import './Review.scss';

class Review extends Component {

    render () {
        return (
           <div className='view-page'>
             <BackButton />
             <Route path="/review/:id" component={ReviewView}/>
             <Route path="/review" exact component={ReviewForm}/>
           </div>
        )
    }
}


const mapState = state => {
    return {

    }
}
const mapDispatch = dispatch => {
    return {

    }
}

export default connect(mapState, mapDispatch)(Review);
