
/**
 * this comp controls the home page and whether user is logged in or not
 */

import React from 'react';
import {withRouter} from 'react-router-dom';

import { connect } from 'react-redux';
import Dashboard from '../Dashboard/Dashboard';
import './HomePage.scss';


const HomePage = (props) => {

    const goTo = path => props.history.push(path)

    return (

        
        props.logged 
        ? <Dashboard />
        :
        <NotLoggedHome goTo={goTo}/>
        
    
    )
}


const mapStateToProps = state => {
    return{
        user: state.user.cognitoUser,
        logged: state.auth.logged
    }
};


export default withRouter(connect(mapStateToProps)(HomePage));




const NotLoggedHome = ({ goTo }) => (
    <div className='home-page'>
        <div className='home-page__center'>
            <WelcomeInfo />
            <div className='login-button btn' onClick={()=>goTo('/login')}>
                Login
            </div>
            <div></div>
            <p className='register-call' onClick={()=>goTo('/register')}>If you don't have an account Register here</p>
        </div>
    </div>

)




const WelcomeInfo = () => (
    <div className='welcome-info'>
        <h2>Welcome</h2>
        <p>For the best experience it is recommended that you log in</p>
    </div>
)