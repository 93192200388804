import React from 'react'
import {withRouter} from 'react-router-dom';
import './GenreCard.scss';

const GenreCard = props => {

    const goTo = () => props.history.push(`/movies/discover/${props.genre.toLowerCase()}`)

    return (
        <div className='genre-card' onClick={ ()=> goTo()}>
            <div  className='genre-card__grad-bar'></div>
            <p className='genre-card__name'>{props.genre}</p>
        </div>
    )
}

export default withRouter(GenreCard);
