import * as actionTypes from '../Actions/ActionTypes';

const initialState = {
  loginLoading: false,
  loginError: {
    status: false,
    msg: ''
  },
  logged: null,
  passwordChanged: false
}

const loginStart = state => {
  return {
    ...state,
    loginLoading: true,
    loginError: {
      status: false,
      msg: ''
    }
  }
}

const setError = (state, msg) => {
  return {
    ...state,
    loginError: {
      status: true,
      msg: 'Username or Password is incorrect'
    }
  }
}

const loggedStatus = (state, action) => {
  return {
    ...state,
    logged: action.logged
  }
}

const passwordChanged = (state, action) => {
  return {
    ...state,
    passwordChanged: true
  }
}


const reducer = (state = initialState, action) => {
  switch(action.type) {
    case(actionTypes.PASSWORD_CHANGED) : return passwordChanged(state);
    case(actionTypes.LOGIN_START) : return loginStart(state);
    case(actionTypes.LOGGED_STATUS) : return loggedStatus(state, action);
    case(actionTypes.LOGIN_FAILED) : return setError(state, action.msg);
    case(actionTypes.LOGIN_SUCCESS) : return
    default: return state;
  }
}

export default reducer;
