import React from 'react'
import './CastCard.scss';
import Poster from '../../../Media/Poster/Poster';

import {withRouter} from 'react-router-dom';


const CastCard = props => {
    const goTo = actorID => props.history.push(`/actors/${actorID}`)
    
    
    return (
        <div className='cast-card' onClick={ ()=> goTo(props.cast.id)}>
            <div className='image__holder'>
                {props.cast.profile_path? <Poster path={props.cast.profile_path} size={200}/>: null}
            </div>
            <div className='data__holder'>
                 <p className='data__holder__name'>{props.cast.name}</p>
                 <p className='data__holder__role'>{props.cast.character}</p>
            </div>
            
        </div>
    )
}

export default withRouter(CastCard);