import React, { Component } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import Poster from '../../../components/Media/Poster/Poster';
import UserIcon from '../../../components/User/Icon/UserIcon';
import { dateFilter, trimText } from '../../../Utility/Utility';
import Comment from '../../../components/Comments/SingleComment';

import * as reviewActions from '../../../store/Actions/Review';
import './ReviewView.scss';


class ReviewView extends Component {
  state = {
    review: null,
    comments: []
  }

  componentDidMount() {
    const reviewId = this.props.match.params.id
    this.props.getReview(reviewId);
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.review !== prevProps.review) {
      this.props.getComments(this.props.review.data._id);
      this.setState({
        review: this.props.review
      })
    }
    if (this.props.comments !== prevProps.comments) {
      this.setState({
        comments: this.props.comments
      })
    }
  }

  goTo = (event, path) => {
    event.stopPropagation();
    this.props.history.push(path);
  };

  render () {

    return (
      <div className='review-view'>
        { this.state.review ?
          <div className='review-holder'>

            <div className='review-card__user-info'>
              <div onClick={(e)=> this.goTo(e, '/profile/' + this.state.review.user._id)} className='review-card__user-info__profile-icon'>
                <UserIcon username={this.state.review.user.username} picture={this.state.review.user.profilePicture}/>
              </div>
              <div className='mrgn-rt'>
                <p className='review-card__user-info__user-txt--username'>{this.state.review.user.username}</p>
                <p className='review-card__user-info__user-txt--date'>{dateFilter(this.state.review.data.updated_at, 'short')}</p>
              </div>
              <div className='review-card__review-info__score'>
                <p className='review-card__review-info__score--data'>{this.state.review.data.score}%</p>
              </div>
            </div>

            <div className='review-card__review-info'>
              <div className='review-card__review-info__movie-poster' onClick={(e) => this.goTo(e, '/movie/' + this.state.review.data.api_code)}>
                <Poster path={this.state.review.data.poster_path} size={200}/>
              </div>
              <div className='review-card__review-info__content'>
                <p className='review-card__review-info__content--header'>{this.state.review.data.headline ? this.state.review.data.headline : this.state.review.data.title}</p>
                <p className='review-card__review-info__content--body'>{trimText(this.state.review.data.content, 300)}</p>
              </div>
            </div>

            <div className='review-card__comments'>
              <p>Comments</p>
              { this.state.comments.length ? this.state.comments.map( comment => {
                return <Comment key={comment.data._id} comment={comment} />
              }) : null }
            </div>
          </div>
        : null
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    review: state.review.review,
    comments: state.review.comments
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getReview: (id) => dispatch(reviewActions.getReviewById(id)),
    getComments: (id) => dispatch((reviewActions.getComments(id)))
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewView));
