import React from 'react'
import './UserCard.scss';
import {withRouter} from 'react-router-dom';



const UserCard = props => {

    console.log('[USER]', props.data)
    const goTo = id => props.history.push(`/profile/${id}`)

     return (
        <div className='user-card' onClick={()=> goTo(props.data.data._id)}>
            <div className='profile__img user-icon'>
              { props.data.data.profilePicture ?
                <img src={`https://s3.us-west-1.amazonaws.com/kritiq/public/profilePics/${props.data.data.username}.jpg`} alt='profileImg' />
                : <p>{props.data.data.username[0]}</p>
              }
            </div>
            <div className='profile__data'>
                <p className='profile__data--username'>{props.data.data.username}</p>
            </div>
        </div>
    )
}


export default withRouter(UserCard);
