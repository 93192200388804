import * as actionTypes from './ActionTypes';
import Kritiq from '../../Api/Kritiq/Library';

export const setProfile = (profile, value) => {
  return {
      type: actionTypes.GET_PROFILE,
      profile: profile,
      value: value
  }
}


export const createComment = (comment, tkn) => {
  console.log('createComment data', comment);
  return dispatch => {
    Kritiq.createDoc('comment', tkn, comment)
    .then(data=> {
      console.log('comment Results', data);
      return
    })
    .catch(err=>{
      console.log('[comment Error]', err);
    })
  }
}
