import React from 'react'


// styles found in Account Settings Comp
const AccountSettingsNavigation = props => {


    return (
        <div className='account-settings__navigation'>
            <div className='navigation__box'>
                <p className='navigation__box__title'>Settings</p>
                <div className='navigation__box__item' onClick={() => props.goTo('/account-settings/change-password')}>
                    Change Password
                </div>
                <div className='navigation__box__item' onClick={() => props.goTo('/account-settings/edit/profile')}>
                    Edit Profile
                </div>

            </div>
        </div>

    )
}

export default AccountSettingsNavigation;
