

import React, {Component} from 'react'

import MediaTileLoader from '../../../Media/MediaTile/MediaTileLoader';
import './HorizontalList.scss';
import MediaTile from '../../../Media/MediaTile/MediaTile';
import UserCard from '../../../User/Card/UserCard';
import GenreCard from '../Cards/GenreCard';
import PlaylistCard from '../Cards/PlaylistCard';
import CastCard from '../../../Credits/Cast/CastCard/CastCard';




class HorizontalList extends Component {


    state = {
        page: 0,
        lastPage: 0,
        
    }

    componentDidMount() {
        this.setTotalPages(this.props.data)
    }
    componentDidUpdate(prevProps) {
        if (prevProps.data != this.props.data) {
            this.setTotalPages(this.props.data)
        }
    }


    setTotalPages = (data) => { 
        if (data) {
            console.log(data.length)
            const updatedTotal = Math.floor(data.length / 5);
            
            this.setState({lastPage: updatedTotal})
        }        
    }


    moveListRight = () => {
        const updatedPage = this.state.page + 1;
        this.setState({page: updatedPage});
    }
    moveListLeft = () => {
        if (this.state.page !== 0 ) {
            const updatedPage = this.state.page - 1;
            this.setState({page: updatedPage});
        }
    }


    render () {
        const createMovieList = () => this.props.data.map((movie, i)=><div  key={i} className='item-spacer'><MediaTile media={movie} type={'movie'}/></div>)
        const createUserList = () => this.props.data.map((user, i)=><UserCard key={i} data={user}/>)
        const createActorList = () => this.props.data.map((actor, i)=><div key={i} className='item-spacer'><CastCard cast={actor} /></div>)
        const createGenreList = () => this.props.data.map((genre, index)=><GenreCard key={index} genre={genre.name} />)
        const createPlaylistList = () => this.props.data.map((playlist, index)=><PlaylistCard key={index} playlist={playlist} />)


        const createList = () => {
            switch(this.props.type) {
                case('movie') : return createMovieList();
                case('user') : return createUserList();
                case('actor') : return createActorList();
                case('genre') : return createGenreList();
                case('playlist') : return createPlaylistList();
                default: return createMovieList();
            }
        }

        const createLoader = () => {
            let sample = [1,2,3,4,5,6, 7, 8, 9,10 ,11, 12]
            return sample.map(item => <MediaTileLoader key={item} />)
        }

        const list = this.props.data ? createList() : createLoader();


        const movment = {
            transform : `translateX(-${100 * this.state.page}%)`
        }



        return (
            <div className='h-list'>

                <div className='h-list__topbar'>
                    <p className='title'>{this.props.title}</p>
                    <div className='h-list__scroll-buttons'>
                        <div className={`scroll__button left ${ this.state.page == 0 ? 'disabled': null} `} onClick={()=> this.moveListLeft()} >
                            <i className="fas fa-chevron-left" ></i>
                        </div>
                        <div className={`scroll__button right ${ this.state.page === this.state.lastPage - 1  ? 'disabled': null}`} onClick={()=> this.moveListRight()}>
                            <i className="fas fa-chevron-right" ></i>
                        </div>
                    </div>
                </div>
                <div className='list__holder' style={movment} >
                    {list}
                </div>
            </div>
        )

    }

}


export default HorizontalList;
