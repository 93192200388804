import React from 'react'
import HorizontalList from '../../../../UI/List/HorizontalList/HorizontalList';



const CreditList = props => {



    return (
        <div className='movie-details__extras'>
            <HorizontalList type={'actor'} title='Cast' data={props.credits.cast}/>
         </div>

    )
}


export default CreditList;
