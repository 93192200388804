import React from 'react';
import { Route } from 'react-router-dom';






/**************************
 Updated 
 ***************************/
import HomePage from '../../Pages/Home/HomePage';
import Login from '../../Pages/Auth/Login/Login';


/**************************
 Still Needs Update å
 ***************************/
import Register from '../../containers/Registration/Register';
import Logout from '../../components/Auth/Logout/Logout';
import VerifyAccount from '../../components/Registration/Verfiy/VerifyAccount';
import Profile from '../../containers/Profile/Profile';
// import EditProfile from '../../containers/Profile/Edit';
import Movie from '../../containers/Media/Movie/Movie';
import Watchlist from '../../containers/Watchlist/Watchlist';
import Search from '../../containers/Search/Search';
import Movies from '../../containers/Movies/MoviesLanding';
import Discover from '../../containers/Movies/Discover/DiscoverLanding';
import Actor from '../../containers/Actors/Actor';
import AccountSettings from '../../components/AccountSettings/AccountSettings';
import Review from '../../containers/Review/Review';
import ProfileTemplate from '../../components/User/Profile/Template/ProfileTemplate';


const content = (props) =>{
  return (
    <div className={props.className}>
        <Route path='/profile-template' component={ProfileTemplate}/>
        <Route path="/review"  component={Review}/>
        <Route path="/account-settings"  component={AccountSettings}/>
        <Route path="/verify-account"  component={VerifyAccount}/>
        <Route path="/register"  component={Register}/>
        <Route path="/login"  component={Login}/>
        <Route path="/logout"  component={Logout}/>
        { /* <Route exact path="/edit/profile"  component={EditProfile} /> */ }
        <Route path="/profile/:id"  component={Profile} />
        <Route exact path="/profile"  component={Profile}/>
        <Route path="/actors/:id"  component={Actor}/>
        <Route path='/movies/discover/:filter' component={Discover} />
        <Route exact path='/movies' component={Movies} />
        <Route path="/movie/:id"  component={Movie} />
        <Route path="/watchlist" component={Watchlist}/>
        <Route path="/search" component={Search}/>
        <Route path="/" exact component={HomePage}/>
    </div>
  )
}

export default content;
