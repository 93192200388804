import React, { Component } from 'react'
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import * as profileActions from '../../store/Actions/Profile';

import WatchlistButton from '../../components/Watchlist/WatchlistButton';
import WatchListView from '../../components/Watchlist/ListView/WatchListListView';


class Watchlist extends Component {
  state = {
    watchlist: null
  }

  componentDidMount () {
    if (this.props.watchlist) {
      this.setState({
        watchlist: this.props.watchlist
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.watchlist !== prevProps.watchlist) {
      console.log('new watchlist', this.props.watchlist);
      this.setState({
        watchlist: this.props.watchlist
      })
    }
  }

  render () {
    return (
      <div className='view-page'>
        { this.state.watchlist ? this.state.watchlist.map( movie => {
            return <WatchListView key={movie._id}movie={movie} ><WatchlistButton data={{id: movie.api_code}}></WatchlistButton></WatchListView>
        }) : null }
      </div>
    )
  }
}

const mapStateToProps = state => {
    return {
      profile: state.profile.userProfile,
      user: state.user.kritiqUser
    }
}
const mapDispatchToProps = dispatch => {
    return {
      fetchProfile: (user) => dispatch(profileActions.getProfile(user))
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Watchlist));



// <div key={movie._id}><WatchlistButton data={{id: movie.api_code}}></WatchlistButton><MediaTile type='movie' media={{id: movie.api_code, poster_path: movie.poster_path}}></MediaTile></div>
