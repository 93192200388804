import * as actionTypes from './ActionTypes';
import Kritiq from '../../Api/Kritiq/Library';

export const setProfile = (profile, value) => {
  return {
      type: actionTypes.GET_PROFILE,
      profile: profile,
      value: value
  }
}

export const setFollows = (followData, id, value) => {
  let temp;
  if (followData) {
    temp = followData
  } else {
    temp = { following: [], followers: [] }
  }
  return {
      type: actionTypes.SET_FOLLOW_DATA,
      followData: temp,
      id: id,
      value: value
  }
}

export const setWatchlist = (watchlistData) => {
  return {
      type: actionTypes.SET_WATCHLIST_DATA,
      watchlistData: watchlistData,
  }
}

export const getPerson = (data) => {
  return Kritiq.viewDoc('profile', 'user_id', data._id)
  .then( results => {
    return {data: data, profile: results.result[0]}
  })
  .catch( err => {
    console.log('[sample person err]', err);
  })
}

export const setUser = (data, value) => {
  return {
    type: actionTypes.SET_USER,
    user: data,
    value: value
  }
}

export const getUser = (id, value) => {
  return dispatch => {
    return Kritiq.getById('user', id)
      .then( user => {
        console.log( 'user gotten', user);
        return dispatch(setUser(user.result, value))
      })
      .catch( err => {
        console.log('[getUser error]', err);
      })
  }
}

export const samplePerson = (person) => {
  return {
    type: actionTypes.SET_SAMPLE,
    samplePerson: person
  }
}

export const setMyReviews = (reviews, value) => {
  return {
    type: actionTypes.SET_MY_REVIEWS,
    myReviews: reviews,
    value: value
  }
}

export const getMyReviews = (user, value) => {
  console.log('getting this persons reviews', user);
  return dispatch => {
    let query = { 'user_id' : user };
    return Kritiq.search('movie_review', query)
    .then( reviews => {
      console.log('reviews', reviews);
      return dispatch(setMyReviews(reviews.result, value))
    })
  }
}

export const getWatchlist = (user, value) => {
  console.log('getting this persons Watchlist', user);
  return dispatch => {
    let query = { 'user_id' : user };
    return Kritiq.search('watchlist', query)
    .then( reviews => {
      console.log('watchlist', reviews);
      return dispatch(setWatchlist(reviews.result, value))
    })
  }
}


export const getProfile = (user, value) => {
  return dispatch => {
    Kritiq.viewDoc('profile', 'user_id', user)
    .then(data=> {
      return dispatch(setProfile(data.result[0], value))
    })
    .catch(err=>{
      console.log('[Get Profile Error]', err);
    })
  }
}

export const saveProfile = (profile) => {
  console.log('saveprofile data', profile);
  return dispatch => {
    Kritiq.updateDoc('profile', profile)
    .then(data=> {
      console.log('updatedProfile data', data);
      return dispatch(setProfile(data.result, true))
    })
    .catch(err=>{
      console.log('[Save Profile Error]', err);
    })
  }
}
