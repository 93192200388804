import * as actionTypes from './ActionTypes';
import * as tmdb from '../../Api/TMDB/Library';
import Kritiq from '../../Api/Kritiq/Library';

export const getMovieDetails = id => {
    return dispatch => {
        tmdb.getMovie(id)
        .then(res=>dispatch({type: actionTypes.GET_MOVIE_SUCCESS, movie: res.data}))
        .then(res=> {
          return tmdb.getCast(res.movie.id);
        })
        .then(res=>dispatch({type: actionTypes.GET_CAST, cast: res}))
        .catch(err=>console.log(err))
    }
}

export const getMovieTrailer = id => {
    return dispatch => {
        tmdb.getTrailer(id)
        .then(res=>{
          console.log('trailerData', res.data);
          res.data.results.forEach( video => {
            if (video.type === 'Trailer') {
              console.log('got a trailer');
              return dispatch({type: actionTypes.GET_MOVIE_TRAILER, trailer: video})
            }
          })
        })
        .catch(err=>console.log(err))
    }
}

export const getMovieReviews = (id) => {
  return dispatch => {
    let query = { 'api_code': id }
    Kritiq.search('movie_review', query)
      .then(results => {
        dispatch({type: actionTypes.GOT_MOVIE_REVIEWS, movieReviews: results.result})
      })
  }
}


export const getCreditDetails = id => {
    return dispatch => {
        tmdb.getCreditsForMovie(id)
        .then(res=>{
            dispatch({type:actionTypes.GET_MOVIE_CREDITS_SUCCESS, credits: res.data})
        })
        .catch(err=>console.log('CREDITS Error',err))
    }
}
