import React from 'react'

export default props => {

   

    return (
        <React.Fragment>
            {
                props.path ? 
                <img src={`https://image.tmdb.org/t/p/${props.size === 'original' ? 'original' :'w' + props.size   }${props.path}`} alt='' />
                : null
            }
        </React.Fragment>

    )
}