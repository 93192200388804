import * as tmdb from '../../Api/TMDB/Library';
import * as actionTypes from './ActionTypes';


export const getActorsMovies = (id) => {
    return dispatch => {
        tmdb.getActorsMovies(id)
        .then(res=>{
          res.data.cast.sort(function(a,b) {
            return b.popularity-a.popularity
          })
          dispatch({type: actionTypes.SET_ACTOR_MOVIES, movies: res.data.cast})
        })
        .catch(err=>console.log(err))
    }
}


export const getActors = () =>{
    return dispatch => {
        tmdb.getActors()
        .then(res=>{
            dispatch({type: actionTypes.SET_ACTORS, actors: res.data.results})
        })
        .catch(err=>console.log(err))
    }
}

export const getActor = (id) =>{
    return dispatch => {
        dispatch({type:actionTypes.SET_ACTOR_START})
        tmdb.getActor(id)
        .then(res=>{
            dispatch({type: actionTypes.SET_ACTOR_SUCCESS, actor: res.data})
        })
        .catch(err=>console.log(err))
    }
}
