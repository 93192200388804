import React, { Component } from 'react'
import { Storage } from 'aws-amplify';
import {connect} from 'react-redux'
import * as userActions from '../../../../../store/Actions/Auth';

class ImageUploader extends Component {

  state = {
    image: null
  }

  setImage = (event) => {
    let file = event.target.files[0];
    Storage.put(`profilePics/${this.props.username}.jpg`, file, {
      contentType: file.type
    })
    .then(res=>{
      console.log(res);
      this.props.updateProfile({
        payload: this.props.user,
        tkn: this.props.cognito.signInUserSession.idToken.jwtToken
      });
    })
    .catch(err=>console.log(err))
  }

  render() {
    return (
      <div>
        <p>Upload Your Image</p>
        <input type="file" id="imageUpload" onChange={(event)=>this.setImage(event)} />
      </div>

    )
  }
}

const mapStateToProps = state => {
    return {
      profile: state.profile.userProfile,
      user: state.user.kritiqUser,
      cognito: state.user.cognitoUser
    }
}
const mapDispatchToProps = dispatch => {
    return {
      updateProfile: (user) => dispatch(userActions.updateProfilePic(user))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ImageUploader);
