import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


import * as actionTypes from '../../store/Actions/ActionTypes';
import * as searchActions from '../../store/Actions/Search';
import * as movieActions from '../../store/Actions/Movies';
import HorizontalList from '../../components/UI/List/HorizontalList/HorizontalList';
// import MediaTile from '../../components/Media/MediaTile/MediaTile';
// import Following from '../../components/Following/Following';

import './Search.scss';


class Search extends Component {

    state = {
      noResults: false,
      featured: null,
      isSearching: false
    }

  componentDidMount () {
    this.props.getFeatured();
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.results !== this.props.results) {
      if (this.props.results.actors.length < 1 && this.props.results.users.length < 1 && this.props.results.movies.length < 1) {
        this.setState({
          noResults: true
        })
      } else {
        this.setState({
          noResults: false
        })
      }
    }

    if (this.props.featured !== prevProps.featured) {
      this.setState({
        featured: this.props.featured
      })
    }
  }

  goTo = (data) => {
    this.props.history.push(data)
  }

  search = () => {
    this.setState({
      isSearching: true
    })
    this.props.searchTerm(this.props.query)
  }



  render () {
    return (
        <div className='search-page view-page'>
          <div className='search-input__holder'>
                <input className='search-input__input' type='text' placeholder='Search Kritiq...' value={this.props.query} onChange={(e) => this.props.setQuery(e.target.value)}/>
                
                <div className='search__button' onClick={() => this.search() }>Search</div>
            
          </div>


            <div className='results--holder' >
              {this.state.noResults && !this.state.isSearching? <p className='no-results'>No results were found</p> : null}
              {this.state.featured && !this.state.isSearching ? <HorizontalList title='Check out these popular movies' data={this.state.featured}/> : null}
              {this.props.results.movies.length > 0 ? <HorizontalList title='Movies' data={this.props.results.movies}/> : null}
              {this.props.results.actors.length > 0 ? <HorizontalList title='Actors' type={'actor'} data={this.props.results.actors}/> : null}
              {this.props.results.users.length > 0 ? <HorizontalList title='Users' type={'user'} data={this.props.results.users}/> : null}
            </div>

        </div>
    )
  }
}

const mapStateToProps = state => {
    return {
		query: state.search.query,
		results: state.search.results,
		user: state.user.kritiqUser,
    featured: state.movies.featured
    }
}
const mapDispatchToProps = dispatch => {
    return {
    getFeatured: () => dispatch(movieActions.getMovies('popular')),
	  searchTerm: (term) => dispatch(searchActions.searchTerm(term)),
	  setQuery: (query) => dispatch({type: actionTypes.SET_SEARCH_QUERY, query: query})
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Search));
