

import Amplify from 'aws-amplify';
import aws_exports from '../aws-exports';


Amplify.configure(aws_exports);
Amplify.configure({
    Auth: {
        identityPoolId: 'us-west-2:c5e305c9-078a-408b-95c5-d45e447a6b5c', 
        region: 'us-west-2',
    },
    Storage: {
        region: "us-west-1",
        bucket: 'kritiq', 
    }
});
