import Kritiq from '../../Api/Kritiq/Library';
import * as NavAction from './Navbar';


export const updateWatchlist = (userID, data, token) => {
  return dispatch => {
    let query = {
      'user_id' : userID,
      'api_code' : data.id
    }
    Kritiq.search('watchlist', query)
    .then( results => {
      if (results.result.length > 0) {
        Kritiq.delete('watchlist', results.result[0]._id, token)
        .then( results => {
          dispatch(NavAction.getWatchlist(userID));
        })
        .catch(err => {
          console.log('delete error', err);
        });
      } else {
        let watchlistObj = {
          user_id: userID,
          api_code: data.id,
          movie_title: data.title,
          poster_path: data.poster,
          media_type: data.type
        }
        Kritiq.createDoc('watchlist', token, watchlistObj)
        .then( results => {
          dispatch(NavAction.getWatchlist(userID));
        })
        .catch(err => {
          console.log('creating Watchlist error', err);
        })
      }
    })
    .catch( err => {
      console.log('[find specific Watchlist error]', err);
    })
  }
}
