import Kritiq from '../../Api/Kritiq/Library';
import * as NavAction from './Navbar';


export const updateFollow = (followerID, followingID, token, value) => {
  return dispatch => {
    let query = {
      'follower' : followerID,
      'following' : followingID
    }

    Kritiq.search('follow', query)
    .then( results => {
      if (results.result.length > 0) {
        Kritiq.delete('follow', results.result[0].data._id, token)
        .then( results => {
          if (value) {
            dispatch(NavAction.getFollows(followerID, value));
          } else {
            dispatch(NavAction.getFollows(followerID, true));
            dispatch(NavAction.getFollows(followingID, value));
          }
        })
        .catch(err => {
          console.log('delete error', err);
        });
      } else {
        let followObj = {
          follower: followerID,
          following: followingID
        }
        Kritiq.createDoc('follow', token, followObj)
        .then( results => {
          if (value) {
            dispatch(NavAction.getFollows(followerID, value));
          } else {
            dispatch(NavAction.getFollows(followerID, true));
            dispatch(NavAction.getFollows(followingID, value));
          }
        })
        .catch(err => {
          console.log('creating follow error', err);
        })
      }
    })
    .catch( err => {
      console.log('[find specific follow error]', err);
    })
  }
}
