const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateUsername = username => {
  const reg = /^[\w.\-+]*$/; // checks for alphanumeric only
  if (!reg.test(username) || username.length > 50 || username.length < 5) {
    return false;
  } else {
    return true;
  }
}

const isEmailInvalid = email => emailRegex.test(email);
const passwordMatch = (password, confirmPassword) => password === confirmPassword;
const validatePassword = (password) => {
  if (password.length > 6) {
    const [lc, uc, nm] = [/[a-z]/, /[A-Z]/, /\d/];
    const validPassword = (pw) => {
      return (
        lc.test(String(pw)) &&
        uc.test(String(pw)) &&
        nm.test(String(pw))
      );
    };
    if (validPassword(password)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}


export const checkRegistrationForError = data => {
  let valid;
  let errorsList = {
    username: [],
    email: [],
    password: [],
    confirm: []
  };

  if (!validatePassword(data.password.value)) {
    errorsList.password.push('Password does not meet requirements.');
  }
  if (!validateUsername(data.username.value)) {
    errorsList.username.push('Username does not meet requirements.');
  }
  if (!isEmailInvalid(data.email.value)) {
    errorsList.email.push('Please provide a valid email');
  }
  if (!passwordMatch(data.password.value, data.confirmPassword.value)) {
    errorsList.confirm.push('Passwords Do Not Match');
  }

  if (errorsList.username.length === 0 && errorsList.email.length === 0 && errorsList.password.length === 0 && errorsList.confirm.length === 0) {
    valid = true;
  } else {
    valid = false
  }
  return {
    validCredentials: valid,
    errors: errorsList
  };

}
