import React from 'react'

import './LoginForm.scss';


const loginForm = props => {


    return (
        <div className='form-holder--login'>
            <div className='login__form'>
                <h3 className='login__header'>Login</h3>
                {/* <p className="notification-holder">{props.header}</p> */}


                <div className='login__error-holder'>
                    {
                        props.error.status
                        ?
                        props.error.msg
                        :
                        null
                    }
                </div>

                <div className='login__inputs-holder'>
                    <div className="input__standard">
                            <input 
                            type='text'  
                            value={props.loginInfo.username} 
                            onChange={(event => props.input(event, 'username'))} 
                            required
                            />

                            <label htmlFor='username' className="floating-label">username</label>
                    </div>
                    <div className="input__standard">
                        <input  
                        type='password' 
                        name='password' 
                        value={props.loginInfo.password}  
                        onChange={(event => props.input(event, 'password'))} 
                        required
                        />
                        <label htmlFor='password' className="floating-label">password</label>
                    </div>

                </div>


                <div className="button__standard" onClick={props.login}>
                    Login
                </div>

            </div>
        </div>
    )
}






export default loginForm;
