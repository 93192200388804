import React from 'react'
import { Button } from '@material-ui/core';
import {withRouter} from 'react-router-dom';


const BackButton = props => {
    
    const goBack = () => props.history.goBack()

    return (
        <div className='back-btn__holder'>
            <Button onClick={()=>goBack()}>
                <i className="fas fa-chevron-left btn-icon"></i>
                Back
            </Button>
        </div>
    )
}


export default withRouter(BackButton)