import React from 'react'
import './UserIcon.scss'


const UserIcon = props => {
    return (
        <div className='user-icon'>
          { props.picture ?
            <img src={`https://s3.us-west-1.amazonaws.com/kritiq/public/profilePics/${props.username}.jpg`} alt='profileImg' />
            : <p>{props.username[0]}</p>
          }
        </div>
    )
}

export default UserIcon;
