import React, {Component} from 'react'
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/Actors';

import ActorViewPage from '../../components/Credits/Actor/ViewPage/ActorViewPage';

class Actor extends Component {

  state = {
    actor: null
  }

    componentDidMount () {
        window.scrollTo(0, 0)
        if (this.props.match.params.id) {
          this.getActorInfo(this.props.match.params.id)
        }
    }

    getActorInfo = id => {
        this.props.getActor(id);
        this.props.getActorsMovies(id)
    }




    render () {
        return <ActorViewPage actor={this.props.actor} movies={this.props.movies}/> 
    }
}


const mapStateToProps = state => {
    return {
        actor: state.actors.actor,
        movies: state.actors.knownFor
    }
}

const mapDispatchToProps = dispatch =>{
    return {
        getActor: (id) => dispatch(actions.getActor(id)),
        getActorsMovies: (id) => dispatch(actions.getActorsMovies(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Actor)
