import React, {Component} from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as authActions from '../../store/Actions/Auth';
import './Dashboard.scss';
import MovieReviewCard from '../../components/Review/ReviewCard/Movie/MovieReviewCard';

import CommentModal from '../../components/Comments/CommentModal';

class Dashboard extends Component {

    state = {
      reviews: null,
      logged: false,
      modal: false,
      singleReview: null
    }

    componentDidMount () {
      this.props.isUserLogged();
      if (this.props.logged === false) {
        // this.props.history.push('/movies')
      } else if (this.props.logged === true) {
        this.setState({
          logged: this.props.logged
        })
      }
      if (this.props.dashboardReviews) {
         this.setState({
           reviews: this.props.dashboardReviews
         })
      }
    }

    componentDidUpdate (prevProps, prevState) {
       if (this.props.dashboardReviews !== prevProps.dashboardReviews) {
          this.setState({
            reviews: this.props.dashboardReviews
          })
       }
       if (this.props.logged !== prevProps.logged) {
         if (!this.props.logged) {
          //  this.props.history.push('/movies')
         }
          this.setState({
            logged: this.props.logged
          })
       }
    }

    toggleModal = (event, review) => {
      event.stopPropagation();
      this.setState({
        modal: !this.state.modal,
        singleReview: review
      })
    }

    render () {



			const createReviewList = reviews => {
        let newArray = [];
        reviews.forEach( person => {
          person.forEach( review => {
            newArray.push(review);
          })
        })
        let updated = newArray.sort(function(a, b) {
          a = new Date(a.data.created_at);
          b = new Date(b.data.created_at);
          return a>b ? -1 : a<b ? 1 : 0;
        });
        return updated.map( (review, index) => {
          return <MovieReviewCard toggleModal={this.toggleModal} key={index} review={review} />
        })
			}



			return (
				<div className='view-page'>
        { this.state.modal ? <CommentModal review={this.state.singleReview} toggleModal={this.toggleModal} /> : null }

          <div className='page-center'>
            <div className='review-holder'>
              { this.state.logged && this.props.user ? <h1>Recent Reviews</h1> : null}
              {this.state.reviews ? createReviewList(this.state.reviews) : null}
            </div>
          </div>

				</div>
			)
    }
}






const mapStateToProps = state => {
    return {
        user: state.user.cognitoUser,
        dashboardReviews: state.profile.dashContent,
        logged: state.auth.logged
    }
}

const mapDispatchToProps = dispatch =>{
    return {
      isUserLogged: () => dispatch(authActions.isUserLogged())
    }
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
