import React from 'react'
import CommentForm from './Form';
import './Comments.scss';

const CommentModal = props => {

    return (
      <div className='modal'>
        <div className='modal-content'>
          <p className='close-icon' onClick={(e) => props.toggleModal(e, null)}><i className="fas fa-times"></i></p>
          <CommentForm toggleModal={props.toggleModal} review={props.review} />
        </div>
      </div>
    )
}

export default CommentModal;
