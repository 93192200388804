import React, { Component } from 'react'
import { Route } from 'react-router-dom';
import {connect} from 'react-redux'
import './AccountSettings.scss';
import AccountSettingsNavigation from './Navigation/AccountSettingsNavigation';
import ChangePassword from './ChangePassword/ChangePassword';
import EditProfile from '../../containers/Profile/Edit';
import UserIcon from '../User/Icon/UserIcon';

class AccountSettings extends Component {
    goTo = path => this.props.history.push(path);

    render() {
        return (
            <div className='view-page'>
                <div className='account-breakdown'>
                   { this.props.user ?
                        <React.Fragment>
                            <UserIcon username={this.props.user.username} picture={this.props.user.profilePicture} />
                            <p className='account-breakdown__username'>{this.props.user.username}</p>
                        </React.Fragment>
                    :null
                    }
                </div>
                <div className='account-settings__holder'>
                    <AccountSettingsNavigation goTo={this.goTo} />
                    <div className='account-settings__view'>
                        <Route path="/account-settings/change-password"  component={ChangePassword}/>
                        <Route path="/account-settings/edit/profile"  component={EditProfile}/>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.kritiqUser
    }
}

export default connect(mapStateToProps)(AccountSettings);
