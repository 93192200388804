import Kritiq from '../../Api/Kritiq/Library';
import * as ProfileAction from './Profile';
import * as actionTypes from './ActionTypes';


export const getUserInfo = (user, place) => {
  return dispatch => {
    dispatch(ProfileAction.getProfile(user._id, true));
    dispatch(getFollows(user._id, true, place));
    dispatch(getWatchlist(user._id));
  }
}

export const getUserReviews = (user) => {
  let query = { 'user_id' : user };
  return Kritiq.search('movie_review', query)
  .then( reviews => {
    return reviews.result
  })
}

export const getDashContent = (following) => {
  return dispatch => {
    let promises = [];
    following.forEach( follow => {
      promises.push(getUserReviews(follow.data.following));
    })
    Promise.all(promises)
    .then(results => {
      dispatch({type: actionTypes.DASH_CONTENT, reviews: results})
    })
    .catch((e) => {
      console.log('getusers search error', e);
    });
  }
}

export const getFollows = (user, value, place) => {
  return dispatch => {
    let query = {
      '~follower':  user,
      '~following': user
    }
    Kritiq.search('follow', query)
    .then( data => {

      if (data.result.length > 0) {
        let followData = {
          following: [],
          followers: []
        }
        data.result.forEach( follow => {
          if (follow.data.follower === user) {
            followData.following.push(follow);
          } else if (follow.data.following === user) {
            followData.followers.push(follow);
          }
        })
        if (followData.following.length > 0) {
          if (place) {
            dispatch(getDashContent(followData.following));
          }
        }

        return dispatch(ProfileAction.setFollows(followData, user, value))
      } else {
        return dispatch(ProfileAction.setFollows(null, user, value))
      }
    })
    .catch(err=>{

    })
  }
}


export const getWatchlist = (user) => {
  return dispatch => {
    let query = {
      'user_id':  user
    }
    Kritiq.search('watchlist', query)
    .then( data => {
      if (data.result.length > 0) {
        return dispatch(ProfileAction.setWatchlist(data.result))
      } else {
        return dispatch(ProfileAction.setWatchlist())
      }
    })
    .catch(err=>{

    })
  }
}
