import React, { Component } from 'react'
import { connect } from 'react-redux';


import * as authActions from '../../../store/Actions/Auth';
import { withRouter } from 'react-router-dom';
import LoginForm from '../../../components/Auth/Login/LoginForm/LoginForm';
import './Login.scss';
import {Link} from 'react-router-dom';

class Login extends Component {

    state = {
      loginCredentails: {
        username: '',
        password: ''
      }
    }

    componentDidMount() {
      this._stateCheck();
    }
    componentDidUpdate(prevProps, prevState) {
        this._stateCheck();
    }

    _stateCheck = () => {
      if (this.props.needsVerification) {
        this.props.history.push('/verify-account')
      }
      if (this.props.user) {
        this.props.history.push('/')
      }
    }

    inputChangeHandler = (event, identifier) => {
        let loginInfo = {...this.state.loginCredentails };
        loginInfo[identifier] = event.target.value
        this.setState({loginCredentails: loginInfo});
    }


    login = () => this.props.login({...this.state.loginCredentails })


    render () {
    
  
      return (
        <div className='login__holder'>
          <LoginForm
            login={this.login}
            input={this.inputChangeHandler}
            loginInfo={this.state.loginCredentails}
            error={this.props.loginError} />


          <div className='login__under-belly'>
            <p className='login__under-txt'>Don't have an Account? </p>
            <Link className='login__register-btn btn underline-grad' to='/register'>Register Here</Link>
          </div>
        </div>
      )
    }
}


const mapStateToProps = state => {
    return {
        user: state.user.cognitoUser,
        loginError: state.auth.loginError,
        needsVerification: state.register.emailVerification
    }
}
const mapDispatchToProps = dispatch => {
    return {
        checkUser: () => dispatch(authActions.isUserLogged()),
        login: (credentails) => dispatch(authActions.logUserIn(credentails))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
