import React from 'react'
import './ActorViewPage.scss';

import HorizontalList from '../../../UI/List/HorizontalList/HorizontalList';
import ActorTopInfo from './ActorTopInfo/ActorTopInfo';

import ActorTopInfoLoader from './ActorTopInfo/Loader';



const ActorViewPage = props => {
    return (
        <div className='actor-view-page view-page'>

            {props.actor ? <ActorTopInfo actor={props.actor}/>  : <ActorTopInfoLoader />}
            {/* */}
            <HorizontalList title={'Known For'} data={props.movies}/>
        </div>
    )
}


export default ActorViewPage;
