import React from 'react'
import Poster from '../../../../Media/Poster/Poster';



const ActorTopInfo = (props) => {


    return (
        <div className='top-hold'>
        <div className='img-holder'>
            <Poster path={props.actor.profile_path} size={200}/>
        </div>
        <div className='data-holder'>
            <p className='actor--name'>{props.actor.name}</p>
        </div>


    </div>
    )
}

export default ActorTopInfo;