// import axios from 'axios-jsonp-pro';
import Axios from 'axios';
const _APIKEY = '?api_key=286abf6056d0a1338f772d1b7202e728';
const _BASEURL = 'https://api.themoviedb.org/3/';
const _LANG = '&language=en-US';
const _REGION = '&region=US';
const _FILTER = '&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_genres='


export const getFeatured = () => {
    const url = `${_BASEURL}movie/popular${_APIKEY + _LANG}`;
    // return axios.jsonp(url)
    return Axios.get(url);
}

export const getTrailer = (id) => {
    const url = `${_BASEURL}movie/${id}/videos${_APIKEY + _LANG}`;
    // return axios.jsonp(url)
    return Axios.get(url);
}

export const getGenreMovies = (genreId) => {
    const url = `${_BASEURL}discover/movie${_APIKEY + _LANG + _REGION + _FILTER + genreId}`;
    // return axios.jsonp(url);
    return Axios.get(url);
}

export const getUpcoming = () => {
    const url = `${_BASEURL}movie/upcoming${_APIKEY + _LANG + _REGION}`;
    // return axios.jsonp(url);
    return Axios.get(url);
}

export const getGenres = () => {
    const url = `${_BASEURL}genre/movie/list${_APIKEY + _LANG}`;
    // return axios.jsonp(url);
    return Axios.get(url);
}

export const getTopRated = () => {
    const url = `${_BASEURL}movie/top_rated${_APIKEY + _LANG}`;
    // return axios.jsonp(url);
    return Axios.get(url);
}

export const getNowPlaying = () => {
    const url = `${_BASEURL}movie/now_playing${_APIKEY + _LANG + _REGION}`;
    // return axios.jsonp(url);
    return Axios.get(url);
}

export const getActors = () => {
    const url = `${_BASEURL}person/popular${_APIKEY + _LANG}`;
    // return axios.jsonp(url);
    return Axios.get(url);
}

export const getActor = (id) => {
    const url = `${_BASEURL}person/${id}${_APIKEY + _LANG}`;
    // return axios.jsonp(url);
    return Axios.get(url);
}

export const getMovie = id => {
    const url = `${_BASEURL}movie/${id}${_APIKEY + _LANG}`;
    // return axios.jsonp(url);
    return Axios.get(url);
}

export const getCast = movieId => {
    const url = `${_BASEURL}movie/${movieId}/credits${_APIKEY + _LANG}`;
    // return axios.jsonp(url);
    return Axios.get(url);
}

export const searchActors = term => {
    const url = `${_BASEURL}search/person${_APIKEY + _LANG}&query=${term}`;
    // return axios.jsonp(url);
    return Axios.get(url);
}

export const searchMovies = term => {
    const url = `${_BASEURL}search/movie${_APIKEY + _LANG}&query=${term}`;
    // return axios.jsonp(url);
    return Axios.get(url);
}

export const getCreditsForMovie = movieID  => {
    const url =  `${_BASEURL}movie/${movieID}/credits${_APIKEY + _LANG}`;
    // return axios.jsonp(url);
    return Axios.get(url);
}

export const getActorsMovies = actorId => {
    const url =  `${_BASEURL}person/${actorId}/movie_credits${_APIKEY + _LANG}`;
    // return axios.jsonp(url);
    return Axios.get(url);
}
