import React from 'react'
import './MovieReviewCard.scss';
import Poster from '../../../Media/Poster/Poster';
import {withRouter} from 'react-router-dom';
import UserIcon from '../../../User/Icon/UserIcon';
import { dateFilter, trimText } from '../../../../Utility/Utility';


const MovieReviewCard = props => {
  const review = props.review.data;
  const user = props.review.user;
  const goTo = (event, path) => {
    event.stopPropagation();
    props.history.push(path);
  };

  return (
    <div className='review-card movie' onClick={(e) => goTo(e, '/review/' + review._id) }>

      <div className='review-card__user-info'>
        <div onClick={(e)=> goTo(e, '/profile/' + user._id)} className='review-card__user-info__profile-icon'>
          <UserIcon username={user.username} picture={user.profilePicture}/>
        </div>
        <div className='review-card__user-info__user-txt'>
          <p className='review-card__user-info__user-txt--username'>{user.username}</p>
          <p className='review-card__user-info__user-txt--date'>{dateFilter(review.created_at, 'short')}</p>
        </div>
        <div className='review-card__review-info__score'>
          <p className='review-card__review-info__score--data'>{review.score/10}</p>
        </div>
      </div>

      <div className='review-card__review-info'>
        <div className='review-card__review-info__movie-poster' onClick={(e) => goTo(e, '/movie/' + review.api_code)}>
          <Poster path={review.poster_path} size={200}/>
        </div>
        <div className='review-card__review-info__content'>
          <p className='review-card__review-info__content--header'>{review.headline ? review.headline : review.title}</p>
          <p className='review-card__review-info__content--body'>{trimText(review.content, 300)}</p>
          <p className='review-card__review-info__content--comment' onClick={(e) => props.toggleModal(e, props.review)}><span>{review.comments !== 0 ? review.comments : null}</span> <i className="far fa-comment-alt"></i></p>
        </div>
      </div>

    </div>
  )
}


export default withRouter(MovieReviewCard);
