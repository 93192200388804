import React, { Component } from 'react'
import {connect} from 'react-redux';
import './ReviewForm.scss';
import Poster from '../../../components/Media/Poster/Poster';
import { Button } from '@material-ui/core';
import * as reviewActions from '../../../store/Actions/Review';
import ReviewScoreDisplay from '../../../components/Review/ReviewScoreDisplay/ReviewScoreDisplay';
import Loader from '../../../components/UI/Loading/Loader/Loader';




class ReviewForm extends Component {

    state = {
        status: '',
        reviewForm: {
            score: 50,
            content: '',
        },
        movieInfo: {
            api_code: '',
            title: '',
            poster_path: '',
            backdrop_path: '',
        }
    }

    componentDidMount() {
        this.mapMovieInfoToState()
    }

    

    componentDidUpdate(prevProps) {

        if (prevProps.review !== this.props.review) {
            this.mapMovieInfoToState()
        }
    }

    mapMovieInfoToState = () => {
        if (this.props.movieInfo) {
            const updatedMovieInfo = {
                api_code: this.props.movieInfo.id,
                title: this.props.movieInfo.title,
                poster_path: this.props.movieInfo.poster_path,
                backdrop_path: this.props.movieInfo.backdrop_path,
            }
            this.setState({movieInfo: updatedMovieInfo, status:'create'})
        } else if ( this.props.review ){
            const updatedMovieInfo = {
                api_code: this.props.review.api_code,
                title: this.props.review.title,
                poster_path: this.props.review.poster_path,
                backdrop_path: this.props.review.backdrop_path,
            }
            const updatedReviewForm = {
                score: this.props.review.score * 10,
                content: this.props.review.content
            }
            this.setState({movieInfo: updatedMovieInfo, reviewForm: updatedReviewForm, status:'edit'})
        }
        else {
            this.setState({status: 'no review'})
        }

    }


    onInputChange = (event, identifer) => {
        let updated = {...this.state.reviewForm}
        updated[identifer] = event.target.value;
        this.setState({reviewForm: updated});
    }


    saveReview = () => {
        const authTkn = this.props.tkn.signInUserSession.idToken.jwtToken
        const payload = {
            user_id: this.props.user._id,
            api_code: this.state.movieInfo.api_code,
            title: this.state.movieInfo.title,
            poster_path: this.state.movieInfo.poster_path,
            backdrop_path: this.state.movieInfo.backdrop_path,
            content: this.state.reviewForm.content,
            score: this.state.reviewForm.score,
        }
        // console.log('[submitting]', { auth: authTkn,  payload: payload, model: 'movie_review'})
        if (this.state.status === 'create') {
            this.props.createReview({ auth: authTkn,  payload: payload, model: 'movie_review'})
        }
        if (this.state.status === 'edit' ) {
            this.props.saveReview({ tkn: authTkn,  payload: {...payload, _id: this.props.review._id}})
        }

    }


    render () {
        return (

            <div className='review__center '>

                {
                    this.state.status === 'no review' ?
                    <p className='no-review-msg'>You Need to Select a Movie to Create a Review</p>
                    :
                    <div className='review-form'>
                        {/* <p className='review-form__title'>{this.state.status === 'create' ? 'Creating' : 'Editing'} a Review For:</p> */}

                        <div className='review-form__movie-breakdown'>
                            <div className='title-holder'>
                                <p>{this.state.movieInfo.title}</p>
                            </div>
                            <div className='poster-holder'>
                                <Poster path={this.state.movieInfo.poster_path} size={'200'}/>
                            </div>
                            
                        </div>


                        <div className='review-form__content'>
                            <div className='review-form__content__score'>
                                {/* <p>Score: {Math.round(this.state.reviewForm.score/10)}</p> */}
                                {/* <ReviewScoreDisplay score={ Math.round(this.state.reviewForm.score/10)} /> */}
                                <p className='review__score'>Score: {this.state.reviewForm.score/10}</p>
                                <input type="range" min="4" max="95" value={this.state.reviewForm.score} onChange={(event)=>this.onInputChange(event, 'score')}/>

                            </div>
                            <div className='review-form__content__comment'>
                                <p>Comments: </p>
                                <textarea value={this.state.reviewForm.content} onChange={(event)=>this.onInputChange(event, 'content')}></textarea>
                            </div>
                            <div className='review__center'>
                                {
                                    this.props.saving ?
                                    <Loader title={'saving...'}/>
                                    :<Button color='primary' variant="contained" onClick={()=>this.saveReview()}>
                                        save
                                    </Button>
                                }
                            </div>
                        </div>


                    </div>
                }

            </div>

        )
    }
}


const mapStateToProps = state => {
    return {
        movieInfo : state.review.movieData,
        user: state.user.kritiqUser,
        tkn: state.user.cognitoUser,
        saving: state.review.saving,
        review: state.review.review
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createReview : (data) => dispatch(reviewActions.createMovieReview(data)),
        saveReview: (data) => dispatch(reviewActions.saveReview(data))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReviewForm);
