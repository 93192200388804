import React from 'react'



const FilterHolder = props => {

    return (
        <div className='filters__holder'>
            <p className='filters__title'>{props.title}</p>
            <div className='filters__section'>
                {props.children}
            </div>
        </div>
    )
}


export default FilterHolder