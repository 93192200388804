import * as actionTypes from './ActionTypes';
import Kritiq from '../../Api/Kritiq/Library';
import * as tmdb from '../../Api/TMDB/Library';
import * as profileActions from './Profile';


export const searchTerm = (term) => {
  return dispatch => {
    dispatch({type: actionTypes.CLEAR_SEARCH})
    tmdb.searchActors(term)
    .then(res=>{
      res.data.results.sort(function(a,b) {
        return b.popularity-a.popularity
      })
      dispatch({type: actionTypes.SEARCH_RESULTS, actors: res.data.results});
      return tmdb.searchMovies(term)
    })
    .then(res=>{
      res.data.results.sort(function(a,b) {
        return b.popularity-a.popularity
      })
      dispatch({type: actionTypes.SEARCH_RESULTS, movies: res.data.results});

      let otherQuery = {
        'username': term
      }
      return Kritiq.search('user', otherQuery)
    })
    .then( res => {
      if (res.result.length > 0) {
        let promises = [];
        res.result.forEach( user => {
          promises.push(profileActions.getPerson(user))
        })
        Promise.all(promises)
        .then(results => {
          dispatch({type: actionTypes.SEARCH_RESULTS, users: results})
        })
        .catch((e) => {
          console.log('getusers search error', e);
        });
      }
    })
    .catch( err => {
      console.log('[search err]', err);
    })
  }
}
