import React, { Component } from 'react'
import { connect } from 'react-redux';
import * as authActions from '../../../store/Actions/Auth';
import { withRouter } from 'react-router-dom';

class Logout extends Component {

    logout = () => {
      localStorage.clear();
      this.props.logout();
      this.props.history.push('/movies');
    }

    componentDidMount() {
      this.logout();
    }

    render () {
        return (
            <div></div>
        )
    }
}


const mapStateToProps = state => {
    return {
    }
}
const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(authActions.logUserOut())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
