import React, {Component} from  'react'
import './DiscoverFilter.scss';
import Button from '@material-ui/core/Button'; 
import FilterHolder from './Holder/FilterHolder';

class DiscoverFilter extends Component {

    state =  {
        showFilter: false,
    }


    toogleFilterHandler = () => {
        const updated = !this.state.showFilter;
        this.setState({showFilter: updated})
    }

    render () {
        return (
            <div className='filter__holder'>
                <div className='btn__holder'>
                    <Button onClick={this.toogleFilterHandler}>
                        <i className="fas fa-sliders-h"></i>
                        <p>Filter</p>
                    </Button>
                </div>
               
                <div className={`filters ${this.state.showFilter ? '': 'hide'}`}>

                    <FilterHolder title={'Genres'}>
                        {this.props.genres.map( (genre, index) => <p className='filterItem' key={index} onClick={() => this.props.update(genre.name)}>{genre.name}</p>)}
                    </FilterHolder>
                    <FilterHolder title={'Other'}>
                            <p className='filterItem' onClick={() => this.props.update('popular')}>Popular</p>
                            <p className='filterItem' onClick={() => this.props.update('now_playing')}>Now Playing</p>
                            <p className='filterItem' onClick={() => this.props.update('upcoming')}>Upcoming</p>
                            <p className='filterItem' onClick={() => this.props.update('top_rated')}>Top Rated</p>
                    </FilterHolder>
                  
        
                </div>

                </div>
        
        )
    }

   
}

export default DiscoverFilter