import React from 'react'




const MovingGradient = props => {
    

    return (
        <div>
            
        </div>
    )
}


export default MovingGradient;