import React, { Component } from 'react'

import {connect} from 'react-redux';
import './ReviewButton.scss';
import * as reviewActions from '../../../store/Actions/Review';
import * as actionTypes from '../../../store/Actions/ActionTypes';

import {withRouter} from 'react-router-dom';


class ReviewButton extends Component {

    componentDidMount() {
        this.checkForReview()
    }
    componentWillUnmount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            this.checkForReview()
        }
    }


    checkForReview = () => {
        if (this.props.user) {
            const queryData = {
                apiID: this.props.movie.id,
                userID: this.props.user._id,
                model: 'movie_review'
            }
            this.props.checkForReview(queryData)
        }
    }

    goToCreateReview = () => {
        this.props.createReviewData(this.props.movie)
        this.props.history.push('/review')
    }

    editReview = () => {
        this.props.history.push('/review')
    }


    render () {


        const createOrShow = () => {
            if (this.props.review === false) {
                return (
                    <div className='review__button create' onClick={this.goToCreateReview}>
                        Create a Review
                    </div>
                )
            }else if (this.props.review)  {
                return (
                    <div className='review__button my-review' onClick={this.editReview}>
                        <p className='title'>Your Score</p>
                        <p className='score'>{this.props.review.score / 10}</p>
                    </div>
                )
            }else {return null}
        }

        return (

            <div className='review__button__outline'>
                {
                    this.props.loading 
                    ?
                    <div className='review__button loading'>Loading</div>
                    :
                    createOrShow()
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.review.fetchingReview,
        review: state.review.review,
        user: state.user.kritiqUser,

    }
}
const mapDispatchToState = dispatch => {
    return {
        checkForReview: (data) => dispatch(reviewActions.checkForReview(data)),
        createReviewData: (movieData) => dispatch({type: actionTypes.SET_MOVIE_DATA_REVIEW, movieData: movieData}),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToState)(ReviewButton))
