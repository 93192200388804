import React, { Component } from 'react'
import picture from '../../../../assets/Movies/jwp.jpg'

import './SideList.scss';


class SideList extends Component {





    render () {


        return (
            <div className='side-list__wrapper'>
                <div className='side-list__topbar'>
                    <p className='side-list__title'>List Header</p>

                   
                </div>
            


                <div className='side-list__list'>
                    <div className='side-list__page'>
                        

                    </div>

                </div>

            </div>
        )
    }
}


export default SideList;