import React, {Component} from 'react'
import './MoviesBanner.scss';
import Slide from './Slide/Slide';



class MoviesBanner extends Component {

    state = {
        page: 0,
        movies: [],
        rightDisabled : false,
        leftDisabled: false,

    }



    componentDidMount () {
        this.setMovies()
    }
    componentDidUpdate (prevProps)  {
        if (prevProps.data !== this.props.data) {
            this.setMovies()
        }
    }

    setMovies = () => {
        if (this.props.data) {
            this.setState({movies: this.props.data})
        }
    }

    slideRight = () => {
        let updated = this.state.page + 1

        if ( updated > this.props.data.length - 1 ) {
            updated = 0;
        }

        this.setState({page: updated})
    }

    slideLeft = () => {
        let updated = this.state.page - 1

        if ( updated < 0 ) {
            updated = this.props.data.length -1 ;
        }

        console.log(updated)
        this.setState({page: updated})
    }






    render () {



        return (
            <React.Fragment >

                <div className='movies-banner'>
                    <div className='arrow arrow--left' onClick={() => this.slideLeft()}>
                        <i className="fas fa-chevron-left"></i>
                    </div>

                        {
                            this.props.data
                            ?
                            this.props.data.map((movie, index)=> {
                               return  (
                                    <Slide
                                    key={index}
                                    index={index}
                                    data={movie}
                                    page={this.state.page}
                                    length={this.props.data.length}/>
                                )
                            })
                            :
                            <React.Fragment>
                                <Slide className={'left-secondary'} data={{}}/>
                                <Slide className={'left'} data={{}}/>
                                <Slide className={'center'} data={{}}/>
                                <Slide className={'right'} data={{}}/>
                                <Slide className={'right-secondary'} data={{}}/>
                            </React.Fragment>


                        }



                    {/* <Slide className={'left-secondary'} data={this.state.movies[0]}/>
                    <Slide className={'left'} data={this.state.movies[1]}/>
                    <Slide className={'center'} data={this.state.movies[2]}/>
                    <Slide className={'right'} data={this.state.movies[3]}/>
                    <Slide className={'right-secondary'} data={this.state.movies[4]}/> */}

                    <div className='arrow arrow--right' onClick={() => this.slideRight()}>
                        <i className="fas fa-chevron-right" ></i>
                    </div>
                </div>



            </React.Fragment>
        )
    }
}


export default MoviesBanner;
