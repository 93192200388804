import * as tmdb from '../../Api/TMDB/Library';
import * as actionTypes from './ActionTypes';

export const getMovies = (type) =>{
    return dispatch => {
      if (type === 'popular') {
        tmdb.getFeatured()
        .then(res=>{
          res.data.results.sort(function(a,b) {
            return b.popularity-a.popularity
          })
            dispatch({type: actionTypes.SET_FEATURED, movies: res.data.results})
        })
        .catch(err=>console.log(err))
      } else if (type === 'now_playing') {
        tmdb.getNowPlaying()
        .then(res=>{
          res.data.results.sort(function(a,b) {
            return b.popularity-a.popularity
          })
            dispatch({type: actionTypes.SET_FEATURED, movies: res.data.results})
        })
        .catch(err=>console.log(err))
      }else if (type === 'upcoming') {
        tmdb.getUpcoming()
        .then(res=>{
          res.data.results.sort(function(a,b) {
            return b.popularity-a.popularity
          })
            dispatch({type: actionTypes.SET_FEATURED, movies: res.data.results})
        })
        .catch(err=>console.log(err))
      } else if (type === 'top_rated') {
        tmdb.getTopRated()
        .then(res=>{
          res.data.results.sort(function(a,b) {
            return b.popularity-a.popularity
          })
            dispatch({type: actionTypes.SET_FEATURED, movies: res.data.results})
        })
        .catch(err=>console.log(err))
      } else {
        tmdb.getFeatured()
        .then(res=>{
          
          res.data.results.sort(function(a,b) {
            return b.popularity-a.popularity
          })
            dispatch({type: actionTypes.SET_FEATURED, movies: res.data.results})
            return tmdb.getNowPlaying()
        })
        .then(res => {
          res.data.results.sort(function(a,b) {
            return b.popularity-a.popularity
          })
          dispatch({type: actionTypes.SET_NOWPLAYING, movies: res.data.results})
          return tmdb.getUpcoming()
        })
        .then(res => {
          res.data.results.sort(function(a,b) {
            return b.popularity-a.popularity
          })
          dispatch({type: actionTypes.SET_UPCOMING, movies: res.data.results})
        })
        .catch(err=>console.log(err))
      }
    }
}

export const getGenres = () => {
  return dispatch => {
    tmdb.getGenres()
    .then(res=>{
        dispatch({type: actionTypes.SET_GENRES, genres: res.data.genres})
    })
    .catch(err=>console.log(err))
  }
}

export const getGenreMovies = (id) => {
  return dispatch => {
    tmdb.getGenreMovies(id)
    .then(res=>{
      console.log('getGenreMovies results', res);
        dispatch({type: actionTypes.SET_GENRE_MOVIES, movies: res.data.results})
    })
    .catch(err=>console.log(err))
  }
}
