import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as followActions from '../../store/Actions/Follow';


class Following extends Component {
  state = {
    user: null,
    follow: false
  }

  componentDidMount () {
    if (this.props.followData) {
      this.checkforFollow();
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.followData !== prevProps.followData) {
      this.setState({
        follow: false
      })
      this.checkforFollow();
    }
  }

  checkforFollow = () => {
    this.props.followData.following.forEach(follow => {
      if (follow.data.following === this.props.other) {
        this.setState({
          follow: true
        })
      }
    })
  }

  updateFollow = (event) => {
    event.stopPropagation();
    this.props.updateFollow(this.props.current, this.props.other, this.props.user.signInUserSession.idToken.jwtToken, this.props.value)
  }

  render () {
    return (
      <div>
        { this.state.follow ? <button onClick={((e) => this.updateFollow(e))}>Unfollow</button> : <button onClick={(e) => this.updateFollow(e)}>Follow</button> }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    followData: state.profile.followData,
    user: state.user.cognitoUser
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateFollow: (followerID, followingID, token, value) => dispatch(followActions.updateFollow(followerID, followingID, token, value))
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Following));
