import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose
} from 'redux';
import thunk from 'redux-thunk';

import AuthReducer from './Reducers/Auth';
import RegisterReducer from './Reducers/Registration';
import userReducer from './Reducers/User';
import reviewReducer from './Reducers/Review'
import MoviesReducer from './Reducers/Movies';
import profileReducer from './Reducers/Profile';
import MovieReducer from './Reducers/Movie';
import SearchReducer from './Reducers/Search';
import ActorsReducer from './Reducers/Actors';

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;


const rootReducer = combineReducers({
  user: userReducer,
  review: reviewReducer,
  auth: AuthReducer,
  register: RegisterReducer,
  profile: profileReducer,
  movies: MoviesReducer,
  movie: MovieReducer,
  search: SearchReducer,
  actors: ActorsReducer
})


const store = createStore(rootReducer,
  composeEnhancers(
    applyMiddleware(thunk)
  )
);



export default store;
