import * as actionTypes from '../Actions/ActionTypes';


const initialState = {
  userProfile: null,
  otherProfile: null,
  otherUser: null,
  followData: null,
  otherFollowData: null,
  samplePerson: null,
  watchlist: null,
  dashContent: null,
  reviews: null,
  myReviews: null
}

const setUser = (state, action) => {
  return {
    ...state,
    otherUser: action.user
  }
}

const setProfile = (state, action) => {
  if (action.value) {
    return {
      ...state,
      userProfile: action.profile
    }
  } else {
    return {
      ...state,
      otherProfile: action.profile
    }
  }
}

const setSample = (state, action) => {
  return {
    ...state,
    samplePerson: action.samplePerson
  }
}

const setWatchlist = (state, action) => {
  return {
    ...state,
    watchlist: action.watchlistData
  }
}


const setFollows = (state, action) => {
    if (action.value) {
      return {
        ...state,
        followData: action.followData
      }
    } else {
      return {
        ...state,
        otherFollowData: action.followData
      }
    }
}

const setDashContent = (state, action) => {
  return {
    ...state,
    dashContent: action.reviews
  }
}

const setReviews = (state, action) => {
  if (action.value) {
    return {
      ...state,
      myReviews: action.myReviews
    }
  } else {
    return {
      ...state,
      reviews: action.myReviews
    }
  }
}

const logout = (state) => {
  return {
    userProfile: null,
    otherProfile: null,
    followData: null,
    otherFollowData: null,
    samplePerson: null,
    watchlist: null,
    dashContent: null,
    reviews: null,
    myReviews: null
  }
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case(actionTypes.GET_PROFILE) : return setProfile(state, action);
    case(actionTypes.SET_FOLLOW_DATA) : return setFollows(state, action);
    case(actionTypes.SET_WATCHLIST_DATA) : return setWatchlist(state, action);
    case(actionTypes.SET_MY_REVIEWS) : return setReviews(state, action);
    case(actionTypes.SET_SAMPLE) : return setSample(state, action);
    case(actionTypes.DASH_CONTENT) : return setDashContent(state, action);
    case(actionTypes.LOGOUT_START) : return logout(state);
    case(actionTypes.SET_USER) : return setUser(state, action);


    default: return state;
  }
}


export default reducer;
