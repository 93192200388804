
import React from 'react';
import './MovieInfoList.scss';
import Poster from '../../../Poster/Poster';
import { timeFilter, moneyFilter } from '../../../../../Utility/Utility';


const MovieInfoList = props => {

    // console.log('[PROPS]', props.details)

    return (
        <div className='movie-info__section'>
            <p className='movie-info__title'>Info</p>

            {
                props.details
                ?
                <div className='list-holder'>

                    <div className='poster'>
                        <Poster path={props.details.poster_path} size={200}/>
                    </div>
                    <div className='info-card large'>
                        <p className='title'>Summary</p>
                        <p className='summary'>{props.details.overview}</p>
                    </div>

                    <div className='info-card'>
                        <p className='title'>Runtime</p>
                        <p className='value'>{timeFilter(props.details.runtime)}</p>
                    </div>

                    <div className='info-card'>
                        <p className='title'>Budget</p>
                        <p className='value '>{moneyFilter(props.details.budget)}</p>
                    </div>

                    <div className='info-card'>
                        <p className='title'>Genres</p>
                        <div className='pill__holder'>
                            {
                                props.details.genres.map(genre=> <p className='pill' key={genre.id}>{genre.name}</p>)
                            }
                        </div>
                    </div>
                    {/* <div className='info-card'>
                        <p className='title'>Production</p>
                        <div className='image-holder'>
                            <Poster path={props.details.production_companies[0].logo_path} size={200} />
                        </div>
                    </div> */}
                </div>
                :
                null
            }

        </div>

    )
}

export default MovieInfoList;
