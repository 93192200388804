import React, { Component } from 'react';
import Content from './Content/Content';
import Navigation from '../containers/Navigation/Navigation';
import './Layout.scss';


class Layout extends Component {

  render () {
    return (
      <div className="page-view">
        <Navigation topClass='page-view--topbar' sideClass='page-view--sidebar' />
        <Content className='page-view--main-view'/>
      </div>
    )
  }
}

export default Layout
