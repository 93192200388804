import * as actionTypes from '../Actions/ActionTypes';


const initalState = {
	fetchingReview: false,
	review: null,
	error: false,
	saving: false,
	movieData: null,
	comments: []
}

const getMovieReviewStart = (state) => {
	return {
		...state,
		fetchingReview: true,
		review: null
	}
}

const gotComments = (state, action) => {
	return {
		...state,
		comments: action.comments
	}
}

const reviewFound = (state, review) => {
	return {
		...state,
		fetchingReview: false,
		review: review.data
	}
}

const gotReview = (state, action) => {
	return {
		...state,
		review: action.review
	}
}

const reviewNotFound = (state) => {
	return {
		...state,
		fetchingReview: false,
		review: false
	}
}
const getMovieReviewSuccess = (state, action) => {
  	return action.review.length > 0 ? reviewFound(state, action.review[0]) : reviewNotFound(state)
}


const reducer = (state = initalState, action) =>{

	switch(action.type) {
		case(actionTypes.GET_MOVIE_REVIEW_START): return getMovieReviewStart(state);
		case(actionTypes.GET_MOVIE_REVIEW_SUCCESS): return getMovieReviewSuccess(state, action);

		case(actionTypes.CREATE_MOVIE_REVIEW_START) : return {...state, saving: true };
		case(actionTypes.CREATE_MOVIE_REVIEW_SUCCESS) : return {...state, saving: false, review: action.review, movieData:null};

		case(actionTypes.SAVE_REVIEW_START) : return {...state, saving: true}
		case(actionTypes.SAVE_REVIEW_SUCCESS): return {...state, saving: false, review: action.review, movieData:null}


		case(actionTypes.SET_MOVIE_DATA_REVIEW) : return {...state, movieData: action.movieData }
		case(actionTypes.CLEAR_REVIEW_DATA) : return initalState
		case(actionTypes.SINGLE_REVIEW) : return gotReview(state, action);
		case(actionTypes.GOT_COMMENTS) : return gotComments(state, action);

		default: return state;
	}
}
export default reducer;
