import * as actionTypes from '../Actions/ActionTypes';

const initialState = {
  featured: [],
  movies : [],
  genres: [],
  nowPlaying: [],
  upcoming: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case (actionTypes.SET_FEATURED) : return {...state, featured: action.movies}
    case (actionTypes.SET_GENRES) : return {...state, genres: action.genres}
    case (actionTypes.SET_NOWPLAYING) : return {...state, nowPlaying: action.movies}
    case (actionTypes.SET_UPCOMING) : return {...state, upcoming: action.movies}
    case (actionTypes.SET_GENRE_MOVIES) : return {...state, featured: action.movies}

    default: return state;
  }
}

export default reducer;
