import * as actionTypes from '../Actions/ActionTypes';

const initialState = {
  actor: null,
  knownFor: null,
  loading: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case (actionTypes.SET_ACTOR_START) : return {...initialState, loading: true,}
    case (actionTypes.SET_ACTOR_SUCCESS) : return {...state, actor: action.actor, loading: false}
    case(actionTypes.SET_ACTOR_MOVIES) : return {...state, knownFor: action.movies}
    default: return state;
  }
}

export default reducer;
