import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';

import AssetUploader from '../../components/User/Profile/EditProfile/ImageUpload/ImageUpload';

import { withRouter } from 'react-router-dom';
import * as profileActions from '../../store/Actions/Profile';


class EditProfile extends Component {
  state = {
    profile: null,
    token: null
  }

  componentDidMount () {
    if (this.props.profile) {
      this.setState({
        profile: this.props.profile
      })
    } else {
      if (this.props.user) {
        this.props.fetchProfile(this.props.user._id);
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      this.props.fetchProfile(this.props.user._id);
    }
    if (prevProps.cognito !== this.props.cognito) {
      this.setState({
        token: this.props.cognito.signInUserSession.idToken.jwtToken
      })
    }
    if (prevProps.profile !== this.props.profile) {
      this.setState({
        profile: this.props.profile
      })
    }
  }

  formChange = (e, type) => {
    let value = e.target.value;
    let newProfile = {...this.state.profile};
    newProfile[type] = value;
    this.setState({
      profile: newProfile
    })
  }

  saveProfile = () => {
    this.props.saveProfile({
      payload: this.state.profile,
      tkn: this.state.token
    })
  }

  goBack = () => {
    this.props.history.goBack()
  }


  render () {
    return (
      <div className='change-password'>
        {this.state.profile ?

          <div>
            <h1 className='change-password__title'>Edit your profile</h1>
            { /* <p>Name: {this.props.profile.first_name} {this.props.profile.last_name}</p>
            <p>Bio: {this.props.profile.bio}</p> */ }
            <form className='form-1'>
              <div className="input-holder">
                <input type='text'  name='first' value={this.state.profile.first_name} onChange={(event) => this.formChange(event, 'first_name')}/>
                <label htmlFor='first' className="floating-label"> First Name</label>
              </div>
              <div className="input-holder">
                <input type='text'  name='last' value={this.state.profile.last_name} onChange={(event) => this.formChange(event, 'last_name')}/>
                <label htmlFor='last' className="floating-label"> Last Name</label>
              </div>
              <div className="input-holder">
                <textarea name='bio' value={this.state.profile.bio} onChange={(event) => this.formChange(event, 'bio')}></textarea>
                <label htmlFor='bio' className="floating-label"> Bio</label>
              </div>
              <div className="input-holder">
                <AssetUploader username={this.props.user.username} />
              </div>
            </form>
            <div className='btn__holder'>
              <Button  variant="contained" color='primary' onClick={() => this.saveProfile()}>
                Save Profile
              </Button>
            </div>
          </div>

        : null }
      </div>
    )
  }
}

const mapStateToProps = state => {
    return {
      profile: state.profile.userProfile,
      user: state.user.kritiqUser,
      cognito: state.user.cognitoUser
    }
}
const mapDispatchToProps = dispatch => {
    return {
      fetchProfile: (user) => dispatch(profileActions.getProfile(user)),
      saveProfile: (profile) => dispatch(profileActions.saveProfile(profile))
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EditProfile));
