import React from 'react'
import './Profile.scss';
import MovingGradient from '../../../UI/MovingGradient/MovingGradient';


const ProfileTemplate = (props) => {



    return (

        <div className='profile__view'>
            <div className='profile__view__user-info user-info'>
                <div className='user-info__wrapper'>

                </div>
                <MovingGradient/>
            </div>
        </div>
    )
}

export default ProfileTemplate;
