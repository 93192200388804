import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';

import Poster from '../../../../Media/Poster/Poster';


// the CSS found for this is in MoviesBanner.js

class Slide extends Component {

    state ={
        className : 'none'
    }


    componentDidMount() {
        this.setup();
    }
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setup()
        }
    }

    goTo = (e, path) => {
      e.stopPropagation();
      this.props.history.push(path);
    }



    setup = () => {
        let index = this.props.index
        let page = this.props.page;

        // const position = this.getPosition(index, page)
        // const className = this.setPosition(position.index, position.page);
        const className = this.setPosition(index, page);
        this.setClass(className)
    }





    setClass = className => this.setState({className: className});






    getLeftPosition = (page) => {
        let positions = {
            left : page - 1,
            leftSecondary: page - 2,
            leftHide: page - 3 ,
        }
        if (page < 3 ) {
            if ( page === 0 ) {
                positions.left = this.props.length - 1
                positions.leftSecondary = this.props.length - 2
                positions.leftHide = this.props.length - 3
            }
            if ( page === 1 ) {
                positions.leftSecondary = this.props.length - 1
                positions.leftHide = this.props.length - 2
            }
            if ( page === 2 ) {
                positions.leftHide = this.props.length - 1
            }
        }
        return positions
    }






    getRightPositions = (page) => {
        let positions = {
            right : page + 1 ,
            rightSecondary: page + 2,
            rightHide: page + 3 ,
        }

        if (page === this.props.length - 1) {
            positions.right = 0
            positions.rightSecondary = 1
            positions.rightHide = 2
        }
        if (page === this.props.length - 2) {
            positions.rightSecondary = 0
            positions.rightHide = 1
        }
        if (page === this.props.length - 3) {
            positions.rightHide = 0
        }


        return positions;
    }

    setPosition = (index, page) => {

        const leftPositions = this.getLeftPosition(page)
        const rightPositions = this.getRightPositions(page)
//
        switch(index) {
            // case(index - page ) : return ''
            case(leftPositions.leftHide) : return 'left-hide'
            case(leftPositions.leftSecondary) : return 'left-secondary'
            case(leftPositions.left) : return 'left'
            case(page) : return 'center'
            case(rightPositions.right) : return 'right'
            case(rightPositions.rightSecondary ) : return 'right-secondary'
            case(rightPositions.rightHide ) : return 'right-hide'
            default: return 'none';
        }
    }






    render () {

        const movie = this.props.data
        return (
            <div className={`${this.state.className} movies-banner__slide`} onClick={(e) => this.goTo(e, '/movie/' + movie.id)}>
                {
                    movie ?
                    <React.Fragment>
                        <div className='movies-banner__slide--backdrop'>
                            <Poster path={movie.backdrop_path} size={'original'}/>
                        </div>
                        <div className='movies-banner__slide--title'>
                            <div className='poster--blur'>
                                <Poster path={movie.backdrop_path} size={'original'}/>
                            </div>
                            <p>
                                {movie.title}
                            </p>
                        </div>

                    </React.Fragment>
                    :null
                }

            </div>
        )

    }


}

export default withRouter(Slide);
