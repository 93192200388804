import React from 'react'
import './Comments.scss';
import UserIcon from '../User/Icon/UserIcon';
import { dateFilter } from '../../Utility/Utility';
import {withRouter} from 'react-router-dom';

const Comment = props => {

  const goTo = (e, path) => {
    props.history.push(path);
  }

    return (
      <div className='comment-div'>
        <div className='review-card__user-info'>
          <div onClick={(e)=> goTo(e, '/profile/' + props.comment.user._id)} className='review-card__user-info__profile-icon'>
            <UserIcon username={props.comment.user.username} picture={props.comment.user.profilePicture}/>
          </div>
          <div className=''>
            <p className='review-card__user-info__user-txt--username'>{props.comment.user.username}</p>
            <p className='review-card__user-info__user-txt--date'>{dateFilter(props.comment.data.updated_at, 'short')}</p>
          </div>
        </div>
        <div className='comment-content'>
          <p>{props.comment.data.content}</p>
        </div>
      </div>
    )
}

export default withRouter(Comment);
