import * as actionTypes from '../Actions/ActionTypes';

const initialState = {
  username: null,
  auth: null,
  cognitoUser: null,
  tkn: null,
  kritiqUser: null
}

const logout = (state, action) => {
  return {
    ...state,
    username: null,
    auth: null,
    cognitoUser: null,
    tkn: null,
    kritiqUser: null
  }
}


const reducer = (state = initialState, action) => {
  switch(action.type) {

    case (actionTypes.SET_USERNAME) : return {...state, username: action.username, auth: action.auth};
    case (actionTypes.SET_USER_COGNITO) : return { ...state, cognitoUser: action.user };
    case(actionTypes.SET_USER_KRITIQ) : return {...state, kritiqUser: action.user};
    case(actionTypes.LOGOUT_START) : return logout(state, action);
    default : return state;
  }
}

export default reducer;

//this is where the token for user auth is
// tkn: signInUserSession.idToken.jwtToken
