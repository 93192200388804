import React from 'react'
import './Loader.scss';


const Loader = props => {

    return (
        <div className='kritiq-loader'>
            <p className='kritiq-loader__title'>{props.title}</p>
        </div>
    )
}


export default Loader;