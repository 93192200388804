import React, { Component } from 'react'
import {connect} from 'react-redux'
import * as commentActions from '../../store/Actions/Comment';
import { Button } from '@material-ui/core';

class CommentForm extends Component {

  state = {
    comment: {
      user_id: this.props.user._id,
      parent_id: this.props.review.data._id,
      content: ''
    }
  }

  createComment = (e) => {
    let tkn = this.props.cognito.signInUserSession.idToken.jwtToken;
    this.props.createComment(this.state.comment, tkn);
    this.props.toggleModal(e);
  }

  inputHandler = (e) => {
    let val = e.target.value;
    let copy = {...this.state.comment};
    copy.content = val;
    this.setState({
      comment: copy
    });
  }

  render() {
    return (
      <div className='comment-form form-1'>
        <div>
          <p>Commenting on: {this.props.review.data.title}</p>
        </div>
        <div className='input-holder'>
          <textarea placeholder='Write your comment' value={this.state.comment.content} onChange={(e) => this.inputHandler(e)}></textarea>
        </div>
        <Button  variant="contained" color='primary' onClick={(e) => this.createComment(e)}>
          Comment
        </Button>
      </div>

    )
  }
}

const mapStateToProps = state => {
    return {
      user: state.user.kritiqUser,
      cognito: state.user.cognitoUser
    }
}
const mapDispatchToProps = dispatch => {
    return {
      createComment: (comment, tkn) => dispatch(commentActions.createComment(comment, tkn))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CommentForm);
