//REGISTRATION
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const CREATE_KRITIQ_USER = 'CREATE_KRITIQ_USER';
export const USER_NEEDS_CONFIRMATION = 'USER_NEEDS_CONFIRMATION';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';

//LOGIN
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOG_USER_IN = 'LOG_USER_IN';
export const LOG_USER_OUT = 'LOG_USER_OUT';
export const IS_USER_LOGGED = 'IS_USER_LOGGED';

// Logout
export const LOGOUT_START = 'LOGOUT_START';

//USER
export const LOGGED_STATUS = 'LOGGED_STATUS';
export const SET_USER_COGNITO = 'SET_USER_COGNITO';
export const SET_USER_KRITIQ = 'SET_USER_KRITIQ';
export const SET_USERNAME = 'SET_USERNAME';

//DASHBOARD
export const GET_FEATURED = 'GET_FEATURED';
export const SET_FEATURED = 'SET_FEATURED';
export const SET_NOWPLAYING = 'SET_NOWPLAYING';
export const SET_GENRE_MOVIES = 'SET_GENRE_MOVIES';
export const SET_UPCOMING = 'SET_UPCOMING';
export const DASH_CONTENT = 'DASH_CONTENT';
export const SET_ACTORS = 'SET_ACTORS';
export const SET_GENRES = 'SET_GENRES';


//PROFILE
export const GET_PROFILE = 'GET_PROFILE';
export const SET_SAMPLE = 'SET_SAMPLE';
export const SET_MY_REVIEWS = 'SET_MY_REVIEWS';
export const SET_USER = 'SET_USER';


//MOVIE
export const GET_CAST = 'GET_CAST';
export const GET_MOVIE_START = 'GET_MOVIE_START';
export const GET_MOVIE_SUCCESS = 'GET_MOVIE_SUCCESS';
export const GET_MOVIE_FAILURE = 'GET_MOVIE_FAILURE';
export const RESET_MOVIE_DETAILS = "RESET_MOVIE_DETAILS";
export const GET_MOVIE_TRAILER = "GET_MOVIE_TRAILER";


// CREDITS
export const GET_MOVIE_CREDITS_START = 'GET_MOVIE_CREDITS_START'
export const GET_MOVIE_CREDITS_SUCCESS = 'GET_MOVIE_CREDITS_SUCCESS'
export const GET_MOVIE_CREDITS_FAIL = 'GET_MOVIE_CREDITS_FAIL'


//Actors
export const SET_ACTOR_START = 'SET_ACTOR_START';
export const SET_ACTOR_SUCCESS = 'SET_ACTOR_SUCCESS'
export const SET_ACTOR_FAIL = 'SET_ACTOR_FAIL'
export const SET_ACTOR_MOVIES = 'SET_ACTOR_MOVIES';


// following
export const SET_FOLLOW_DATA = 'SET_FOLLOW_DATA';


//REVIEWS
export const GOT_MOVIE_REVIEWS = 'GOT_MOVIE_REVIEWS';
export const GET_MOVIE_REVIEW_START = 'GET_MOVIE_REVIEW_START';
export const GET_MOVIE_REVIEW_SUCCESS = 'GET_MOVIE_REVIEW_SUCCESS';
export const GET_MOVIE_REVIEW_FAILURE= 'GET_MOVIE_REVIEW_FAILURE';
export const CREATE_MOVIE_REVIEW_START = 'CREATE_MOVIE_REVIEW_START';
export const CREATE_MOVIE_REVIEW_SUCCESS = 'CREATE_MOVIE_REVIEW_SUCCESS';
export const CREATE_MOVIE_REVIEW_FAILURE= 'CREATE_MOVIE_REVIEW_FAILURE';
export const SET_MOVIE_DATA_REVIEW = 'SET_MOVIE_DATA_REVIEW';
export const SINGLE_REVIEW = 'SINGLE_REVIEW';
export const GOT_COMMENTS = 'GOT_COMMENTS';


export const SAVE_REVIEW_START = 'SAVE_REVIEW_START';
export const SAVE_REVIEW_SUCCESS = 'SAVE_REVIEW_SUCCESS';



export const CLEAR_REVIEW_DATA = 'CLEAR_REVIEW_DATA'

//Watchlist
export const SET_WATCHLIST_DATA = 'SET_WATCHLIST_DATA';

// search
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'
export const SEARCH_RESULTS = 'SEARCH_RESULTS';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
