import React, { Component } from 'react'
import {connect} from 'react-redux';
import * as actions from '../../../store/Actions/Auth';

import './ChangePassword.scss';
import { Button } from '@material-ui/core';



class ChangePassword extends Component {


    state = {
        passwordForm: {
            old: '',
            new: '',
            confirmNew: ''
        },
        passwordChanged: false,
        notMatch: false
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.props.passwordChanged !== prevProps.passwordChanged && this.props.passwordChanged) {
        this.setState({
          passwordChanged: true,
          passwordForm: {
              old: '',
              new: '',
              confirmNew: ''
          },
        }, this.removeMessage())
      }
    }

    removeMessage = () => {
      setTimeout(function () {
        this.setState({
          passwordChanged: false
        })
      }.bind(this), 3000);
    }



    inputChangeHandler  = (event, identifier) => {
        const updatedForm = {...this.state.passwordForm}
        updatedForm[identifier] = event.target.value;
        this.setState({passwordForm: updatedForm})
    }

    submit = () => {
      if (this.state.passwordForm.new === this.state.passwordForm.confirmNew) {
        this.setState({
          notMatch: false
        })
        this.props.passwordChange(this.state.passwordForm.old, this.state.passwordForm.new);
      } else {
        this.setState({
          notMatch: true
        })
      }
    }


    render () {
        return (
            <div className='change-password'>
              <p className='change-password__title'>Change Password</p>

              <div className='form-1'>
                <div className="input-holder">
                  <input type='password'  name='old' value={this.state.passwordForm.old} onChange={(event => this.inputChangeHandler(event, 'old'))}/>
                  <label htmlFor='old' className="floating-label"> Old Password</label>
                </div>
                <div className="input-holder">
                  <input type='password'  name='new' value={this.state.passwordForm.new} onChange={(event => this.inputChangeHandler(event, 'new'))}/>
                  <label htmlFor='new' className="floating-label"> New Password</label>
                </div>
                <div className="input-holder">
                  <input type='password'  name='cnew' value={this.state.passwordForm.confirmNew} onChange={(event => this.inputChangeHandler(event, 'confirmNew'))}/>
                  <label htmlFor='cnew' className="floating-label"> Confirm New Password</label>
                </div>

              </div>
              <div className='btn__holder'>
                <Button  variant="contained" color='primary' onClick={this.submit}>
                  Change Password
                </Button>
              </div>
              { this.state.passwordChanged ? <p>Successfully changed password!</p> : null }
              { this.state.notMatch ? <p>Passwords don't match</p> : null }

            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
      passwordChanged: state.auth.passwordChanged
    }
}
const mapDispatchToProps = dispatch => {
    return {
      passwordChange: (oldPassword, newPassword) => dispatch(actions.ChangePassword(oldPassword, newPassword))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
