import React from 'react'
import './MediaTile.scss';
import Poster from '../Poster/Poster';
import { withRouter } from 'react-router-dom';
import { trimText } from '../../../Utility/Utility';


const MediaTile = props => {

    const goTo = path => props.history.push(path)

    return (
        <div className='media-tile' onClick={()=> goTo(`/${props.type}/${props.media.id}`)}>

          <div className='poster__holder'>
             <Poster size={500} path={props.media.poster_path}/>
          </div>
          <div className='title-holder'>
            {trimText(props.media.title, 38)}
          </div>
        </div>
    )
}


export default withRouter(MediaTile);
