let longOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
};

let shortOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
};

let timeOptions = {
  hour: 'numeric',
  minute: 'numeric'
};

// for formatting date objects
export const dateFilter = (date, filter) => {
  let newDate;
  if (filter === 'long') {
    newDate = new Date(date).toLocaleDateString("en-US", longOptions);
  }

  if (filter === 'short') {
    newDate = new Date(date).toLocaleDateString("en-US", shortOptions);
  }

  if (filter === 'time') {
    newDate = new Date(date).toLocaleDateString("en-US", timeOptions);
    let index = newDate.indexOf(',');
    newDate = newDate.slice(index + 1, newDate.length);
  }
  return newDate;
}

// for formatting movie length
export const timeFilter = (n) => {
  var hours = Math.floor(n / 60);
  var minutes = n % 60;
  let string = hours > 1 ? hours + " hrs " + minutes + " min" : hours + " hr " + minutes + " min";
  return string;
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})

export const moneyFilter = num => {
  let monNum = formatter.format(num);
  let count = 0;
  for (var i = 0; i < monNum.length; i++) {
    if (monNum.charAt(i) === ',') {
      count++;
    }
  }
  if (count === 2) {
    return monNum.split(',')[0] + 'M'
  } else if (count === 1) {
    return monNum.split(',')[0] + 'K'
  } else {
    return monNum
  }
}

//for triming text to cetain size
export const trimText = (string, length) => {
  if (string) {
    if (string.length > length) {
      let trimmedString = string.substr(0, length)
      if (trimmedString[trimmedString.length - 1] === ' ' || trimmedString[trimmedString.length - 1] === ',' || trimmedString[trimmedString.length - 1] === '.') {
        trimmedString = trimmedString.substr(0, length-1)
      }
      let croppedLength = trimmedString.length;
      do {
        trimmedString = string.substr(0, croppedLength-1);
        croppedLength--;
      }
      while(string[croppedLength] !== ' ' || string[croppedLength-1] === '.' || string[croppedLength-1] === ',' || string[croppedLength-1] === ':');
      return trimmedString + '...';
    } else {
      return string;
    }
  } else {
    return string;
  }
}
